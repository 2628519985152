import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { colors as themeColors } from "../styles/colors";

export const CompetitorItem = ({ data, index }) => {
	const colors = useMemo(() => {
		if (data.userBrand) {
			return {
				text: "primary.main",
				background: themeColors.lightLightPrimary,
			};
		}

		return {
			text: "black.main",
			background: index % 2 === 0 ? "#FAFAFB" : themeColors.white,
		};
	}, [data.userBrand, index]);

	return (
		<Box
			key={data.name}
			data-testid="competitor-item"
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				height: "55px",
				backgroundColor: colors.background,
				borderRadius: "2px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					width: "28%",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Nunito",
						fontSize: 12,
						lineHeight: "16px",
						width: "48px",
						textAlign: "center",
						mr: "40px",
						color: colors.text,
					}}
				>
					{index + 1}
				</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						width: "150px",
					}}
				>
					<img
						alt="Logo Competitor"
						data-testid="competitor-item-image"
						src={data.picture}
						style={{
							width: "37px",
							height: "37px",
							borderRadius: "50%",
							marginRight: "10px",
						}}
					/>
					<Typography
						sx={{
							fontFamily: "Nunito",
							fontSize: 12,
							fontWeight: 600,
							lineHeight: "16px",
							color: "primary.main",
						}}
						data-testid="competitor-item-name"
					>
						{data.name}
					</Typography>
				</Box>
			</Box>
			<Typography
				sx={{
					width: "18%",
					fontSize: 12,
					lineHeight: "14px",
					textAlign: "center",
					color: colors.text,
				}}
				data-testid="competitor-item-transactions"
			>
				{data.noTransactions.toLocaleString()}
			</Typography>
			<Typography
				sx={{
					fontFamily: "Nunito",
					fontSize: 12,
					lineHeight: "16px",
					width: "18%",
					textAlign: "center",
					color: colors.text,
				}}
				data-testid="competitor-item-aov"
			>
				{data.aov.toLocaleString()}
				<Box
					sx={{
						fontFamily: "Roboto",
						fontSize: 10,
						lineHeight: "12px",
					}}
					component="span"
				>
					{` SAR`}
				</Box>
			</Typography>
			<Typography
				sx={{
					fontFamily: "Nunito",
					fontSize: 12,
					lineHeight: "16px",
					width: "18%",
					color: colors.text,
					textAlign: "center",
				}}
				data-testid="competitor-item-sales"
			>
				{data.sales.toLocaleString()}
				<Box
					sx={{
						fontFamily: "Roboto",
						fontSize: 10,
						lineHeight: "12px",
					}}
					component="span"
				>
					{` SAR`}
				</Box>
			</Typography>
			<Box
				sx={{
					width: "18%",
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<Typography
					sx={{
						width: "106px",
						textAlign: "center",
						color: colors.text,
						fontFamily: "Nunito",
						fontSize: 12,
						lineHeight: "16px",
					}}
					data-testid="competitor-item-customers"
				>
					{data.noCustomers.toLocaleString()}
				</Typography>
			</Box>
		</Box>
	);
};
