import { isSameDay, subDays, subYears, startOfDay, endOfDay } from "date-fns";

const defineds = {
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOf30days: subDays(new Date(), 29),
	startOf1year: subYears(new Date(), 1),
	startOfAllTime: subYears(new Date(), 100),
};

const staticRangeHandler = {
	range: {},
	isSelected(range) {
		const definedRange = this.range();
		return (
			isSameDay(range.startDate, definedRange.startDate) &&
			isSameDay(range.endDate, definedRange.endDate)
		);
	},
};

export function createStaticRanges(ranges) {
	return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
	{
		label: "All Time",
		range: () => ({
			startDate: defineds.startOfAllTime,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: "1 Year",
		range: () => ({
			startDate: defineds.startOf1year,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: "30 Days",
		range: () => ({
			startDate: defineds.startOf30days,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: "Today",
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
]);
