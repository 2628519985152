import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { BarChart } from "./BarChart";
import { SelectStatisticType } from "./SelectStatisticType";

export const CustomBarChart = ({
	selectedType,
	setSelectedType,
	chart,
	name,
	containerStyle,
}) => (
	<Box
		sx={{
			boxShadow: 1,
			borderRadius: 3,
			backgroundColor: "white.main",
			height: 292,
			p: "20px",
			...containerStyle,
		}}
	>
		<Box display="flex" justifyContent="space-between">
			<Typography
				data-testid="custom-bar-chart-name"
				sx={{ fontWeight: 700, fontSize: 18 }}
			>
				{name}
			</Typography>
			{setSelectedType ? (
				<SelectStatisticType
					selectedType={selectedType}
					setSelectedType={setSelectedType}
					name={name}
				/>
			) : null}
		</Box>
		<Divider sx={{ my: 2 }} />
		<BarChart {...chart} />
	</Box>
);
