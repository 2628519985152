import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { TextInputNew } from "./TextInputNew";
import { StatusTag } from "./StatusTag";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillings, updateBillings } from "store/settingSlice";
import { showSnackBar } from "store/uiSlice";
import { Select } from "./Select";
import { LoadingButton } from "@mui/lab";

const BILLING_PLANS = {
	"Pre-Paid Portfolio Account": "Pre-Paid Portfolio Account",
	"Post-Paid Portfolio Account": "Post-Paid Portfolio Account",
	"VIP Portfolio Account": "VIP Portfolio Account",
};

const HEIGHT = 292;

export const BillingSettings = () => {
	const dispatch = useDispatch();
	const [subscriptions, setSubscriptions] = useState([]);
	const [subscription, setSubscription] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);

	const loading = useSelector((state) => state.settings.loading);
	const updateBillingLoading = useSelector(
		(state) => state.settings.updateBillingLoading,
	);
	const billing = useSelector((state) => state.settings.billings);
	const prePaidPlan = useSelector((state) =>
		state.settings.billings?.find(
			(plan) => plan.name === BILLING_PLANS["Pre-Paid Portfolio Account"],
		),
	);

	const handleSubscriptionChange = (e) => {
		setSubscription(subscriptions.find((plan) => plan.id === e.target.value));
		setHasChanged(true);
	};

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleCancel = () => {
		setIsEditing(false);
		setHasChanged(false);
	};

	const handleSave = () => {
		const onSuccess = () => {
			dispatch(
				showSnackBar({
					text: `Subscription updated successfully to ${subscription.name}`,
					severity: "success",
					duration: 3000,
				}),
			);
		};

		const onFinish = () => {
			setIsEditing(false);
			setHasChanged(false);
		};

		dispatch(
			updateBillings({
				billings: {
					typeId: 1,
					planId: subscription.id,
				},
				onSuccess,
				onFinish,
			}),
		);
	};

	const handlePaymentDate = () => {
		const paymentDate = new Date(
			subscription?.nextPaymentDay,
		).toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		if (paymentDate === "Invalid Date") {
			return "N/A";
		}
		return paymentDate;
	};

	useEffect(() => {
		if (prePaidPlan) {
			setSubscriptions(prePaidPlan.paymentPlan);
			const currentSubscription = prePaidPlan.paymentPlan.find(
				(plan) => plan.selected === true,
			);
			setSubscription(currentSubscription);
		}
	}, [prePaidPlan]);

	useEffect(() => {
		dispatch(fetchBillings());
	}, [dispatch]);

	if (loading) {
		return (
			<Skeleton
				variant="rounded"
				animation="wave"
				height={HEIGHT}
				sx={{
					borderRadius: "20px",
				}}
			/>
		);
	}

	if (billing === null) {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: HEIGHT,
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "19px",
						color: "#4D4D4D",
					}}
				>
					No billing details found
				</Typography>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateRow: "1fr 1fr",
				gap: "24px",
			}}
		>
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				<Box>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: "500",
								lineHeight: "19.2px",
								color: "#4D4D4D",
								marginRight: "16px",
							}}
						>
							Subscription Details
						</Typography>
						<StatusTag statusId={4} />
					</Grid>

					<Typography
						sx={{
							fontSize: "12px",
							fontWeight: "400",
							lineHeight: "14.4px",
							color: "#4D4D4D",
							marginTop: "5px",
						}}
					>
						View your plan
					</Typography>
				</Box>
				<Box
					sx={{
						marginTop: "24px",
					}}
				>
					<Box
						sx={{
							width: "30%",
						}}
					>
						<Select
							name={"id"}
							label="Subscription Plan"
							value={subscription?.id || "No plan available"}
							onChange={handleSubscriptionChange}
							items={subscriptions}
							disabled={!isEditing}
						/>
					</Box>

					<Box
						display="flex"
						columnGap={"24px"}
						sx={{
							marginTop: "20px",
						}}
					>
						<TextInputNew
							label="Next payment on"
							value={handlePaymentDate()}
							containerStyle={{ width: "30%" }}
							disabled
						/>
						<TextInputNew
							label="Next Payment Amount"
							value={subscription?.nextPaymentAmount.toLocaleString() || "N/A"}
							containerStyle={{ width: "30%" }}
							endTitle="SAR"
							disabled
						/>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "24px",
					}}
				>
					{!isEditing ? (
						<Button
							variant="contained"
							color="primary"
							onClick={handleEdit}
							sx={{
								width: "104px",
								height: "40px",
								borderRadius: "10px",
							}}
						>
							Edit
						</Button>
					) : (
						<>
							<Button
								variant="outlined"
								color="info"
								onClick={handleCancel}
								sx={{
									width: "104px",
									height: "40px",
									borderRadius: "10px",
									mr: 2,
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={updateBillingLoading}
								variant="contained"
								color="primary"
								onClick={handleSave}
								disabled={!hasChanged}
								sx={{
									width: "188px",
									height: "40px",
									borderRadius: "10px",
								}}
							>
								Save Changes
							</LoadingButton>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};
