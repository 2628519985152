import React, { useCallback, useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { Icon } from "./Icon";

export const MultiSelectCompetitors = ({
	name,
	competitors,
	selectedItems,
	setSelectedItems,
	isLoading,
	containerStyle = {},
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	const onChange = useCallback(
		(e) => {
			const { value } = e.target;

			if (!value.length) {
				setSelectedItems([]);
				return;
			}

			setSelectedItems(value);
		},
		[selectedItems, competitors],
	);

	const renderSelectedValues = useCallback((items) => {
		return (
			<Box display="flex">
				{items.map((item, i) => {
					return (
						<Typography
							key={`selected-item-${item.id}`}
							variant="outlined"
							sx={{
								fontSize: "18px",
								lineheight: "27px",
								fontFamily: "Inter",
								color: "#232323",
								m: "2px",
							}}
						>
							{item.name}
							{i === items.length - 1 ? "" : ","}
						</Typography>
					);
				})}
			</Box>
		);
	}, []);

	const renderAllValues = useCallback(
		(item) => {
			const checked = selectedItems.some(
				(selectedItem) => selectedItem.id === item.id,
			);

			return (
				<MenuItem key={item.id} value={item}>
					<Checkbox
						sx={{ height: "15px", width: "15px", mr: "10px" }}
						checked={checked}
					/>
					{item.name}
				</MenuItem>
			);
		},
		[selectedItems],
	);

	return (
		<Box sx={containerStyle}>
			{isLoading ? (
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "59px",
					}}
				>
					<CircularProgress size={30} />
				</Box>
			) : (
				<Box
					sx={{
						width: "100%",
						position: "relative",
						boxSizing: "border-box",
						height: "59px",
					}}
				>
					<Typography
						sx={{
							position: "absolute",
							left: "12px",
							top: "16px",
							transition: "0.3s",
							fontFamily: "Inter",
							fontSize: "18px",
							transform:
								isFocused || selectedItems.length
									? "translateY(-27px) scale(0.777)"
									: "translateY(0) scale(1)",
							transformOrigin: "left top",
							fontWeight: "400",
							textShadow: isFocused
								? "0 0 0.5px #61AEA0"
								: selectedItems.length
									? "0 0 0.5px #9A9A9A"
									: "none",
							lineHeight: "27px",
							color: isFocused ? "#61AEA0" : "#9A9A9A",
							backgroundColor: "white.main",
							zIndex: 2,
							pointerEvents: "none",
							padding: "0 4px",
						}}
					>
						{name}
					</Typography>
					<Select
						multiple
						value={selectedItems}
						onChange={onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						renderValue={renderSelectedValues}
						IconComponent={() => (
							<Icon
								name="arrowDownMultiSelect"
								style={{
									position: "absolute",
									width: "16px",
									height: "16px",
									right: "16px",
								}}
							/>
						)}
						sx={{
							"borderRadius": "10px",
							"height": "59px",
							"backgroundColor": "white.main",
							"width": "100%",
							"fontSize": "16px",
							"fontFamily": "Inter",
							"color": "#232323",
							"paddingRight": "40px",
							"& .MuiOutlinedInput-notchedOutline": {
								borderWidth: "1.5px",
								borderColor: "#D9D9D9",
							},
							"&:hover .MuiOutlinedInput-notchedOutline": {
								borderColor: "#D9D9D9",
							},
							"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								borderColor: "#61AEA0",
							},
						}}
					>
						{competitors.map(renderAllValues)}
					</Select>
				</Box>
			)}
		</Box>
	);
};
