import React from "react";
import packageJson from "../../package.json";
import { Typography } from "@mui/material";
import { colors } from "../styles/colors";

export const Version = () => {
	return (
		<Typography
			sx={{
				fontSize: 12,
				color: colors.darkGray,
				position: "absolute",
				bottom: 0,
				right: "16px",
			}}
		>{`v${packageJson.version}`}</Typography>
	);
};
