import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Auth } from "../pages/Auth";
import { Offers } from "../pages/Offers";
import { Performance } from "../pages/Performance";
import { useDispatch, useSelector } from "react-redux";
import { CreateOfferGeneral } from "../modal/CreateOfferGeneral";
import { setAccessToken } from "../store/authSlice";
import { CreateOfferLocation } from "../modal/CreateOfferLocation";
import { CreateOfferClientOf } from "../modal/CreateOfferClientOf";
import { Competition } from "../pages/Competition";
import { CustomerBehavior } from "../pages/CustomerBehavior";
import { Alert, Box, CssBaseline, Snackbar } from "@mui/material";
import { Drawer } from "./Drawer";
import { OfferDetails } from "../modal/OfferDetails";
import { hideSnackBar } from "../store/uiSlice";
import { EditOfferGeneral } from "../modal/EditOfferGeneral";
import { EditOfferLocation } from "../modal/EditOfferLocation";
import { EditOfferClientOf } from "../modal/EditOfferClientOf";
import { DeleteOffer } from "../modal/DeleteOffer";
import { Home } from "../pages/Home";
import { Settings } from "../pages/Settings";
import { CreateBrand } from "../modal/CreateBrand";
import { fetchUserInfo } from "store/brandsSlice";

export const Navigation = () => {
	const dispatch = useDispatch();
	const accessToken = useSelector(({ auth }) => auth.accessToken);
	const snackBar = useSelector(({ ui }) => ui.snackBar);
	const [isLoading, setIsLoading] = useState(true);

	const brands = useSelector(({ brands }) => brands.brands);

	const handleCloseSnackBar = () => {
		dispatch(hideSnackBar());
	};

	useEffect(() => {
		if (!isLoading) {
			return;
		}

		const accessTokenStorage = localStorage.getItem("accessToken");
		if (accessTokenStorage) {
			dispatch(setAccessToken(accessTokenStorage));
		}

		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (accessToken && !brands.length) {
			dispatch(fetchUserInfo());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken, brands.length]);

	if (isLoading) {
		return null;
	}

	return (
		<BrowserRouter>
			<Box display="flex" sx={{ backgroundColor: "gray.lightLight" }}>
				{accessToken ? <Drawer /> : null}
				<Routes>
					{accessToken ? (
						<>
							<Route path="/" element={<Home />} />
							<Route path="offers" element={<Offers />}>
								<Route
									path="create-offer-general"
									element={<CreateOfferGeneral />}
								/>
								<Route
									path="create-offer-location"
									element={<CreateOfferLocation />}
								/>
								<Route
									path="create-offer-client-of"
									element={<CreateOfferClientOf />}
								/>
								<Route path="offer-details" element={<OfferDetails />}>
									<Route path="delete-offer" element={<DeleteOffer />} />
								</Route>
								<Route
									path="edit-offer-general"
									element={<EditOfferGeneral />}
								/>
								<Route
									path="edit-offer-location"
									element={<EditOfferLocation />}
								/>
								<Route
									path="edit-offer-client-of"
									element={<EditOfferClientOf />}
								/>
							</Route>
							<Route path="performance" element={<Performance />} />
							<Route path="competition" element={<Competition />} />
							<Route path="customer-behavior" element={<CustomerBehavior />} />
							<Route path="settings" element={<Settings />}>
								<Route path="create-brand" element={<CreateBrand />} />
							</Route>
						</>
					) : (
						<Route path="/" element={<Auth />} />
					)}
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</Box>
			<CssBaseline />
			<Snackbar
				open={snackBar.open}
				autoHideDuration={snackBar.duration}
				onClose={handleCloseSnackBar}
			>
				<Alert onClose={handleCloseSnackBar} severity={snackBar.severity}>
					{snackBar.text}
				</Alert>
			</Snackbar>
		</BrowserRouter>
	);
};
