import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetCustomerPesonas } from "../api";
import { errorHandler } from "../utils/errorHandling";

const customerPersonasInitialState = {
	customerPersonas: null,
};

export const fetchCustomerPersonas = createAsyncThunk(
	"customerPersonas/fetchCustomerPersonas",
	async ({ onFinish }) => {
		try {
			const response = await apiGetCustomerPesonas();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

const customerPersonasSlice = createSlice({
	name: "customerPersonas",
	initialState: customerPersonasInitialState,
	reducers: {
		resetCustomerPersonas: (state) => {
			state.customerPersonas = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCustomerPersonas.fulfilled, (state, { payload }) => {
			state.customerPersonas = payload;
		});
	},
});

export const customerPersonasReducer = customerPersonasSlice.reducer;
export const { resetCustomerPersonas } = customerPersonasSlice.actions;
