import React, { useEffect, useState } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
	editOffer as editOfferStore,
	setEditOffer,
} from "../store/offersSlice";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { MultiSelectWithSearch } from "../components/MultiSelectWithSearch";
import { showSnackBar } from "../store/uiSlice";
import { modifyCreateOffer } from "../utils/offerUtils";
import { ModalContainer } from "./ModalContainer";
import { SelectedItem } from "../components/SelectedItem";

export const EditOfferClientOf = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const catalogs = useSelector(({ offers }) => offers.catalogs);
	const editOffer = useSelector(({ offers }) => offers.offerEdit);
	const [selectedClientOf, setSelectedClientOf] = useState([]);
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setSelectedClientOf(editOffer.clientOf);
	}, [editOffer.clientOf]);

	const handleClose = () => {
		navigate("/offers");
		setSelectedClientOf([]);
		dispatch(setEditOffer({}));
		setError("");
	};

	const handleReset = () => {
		setSelectedClientOf([]);
		setError("");
	};

	const handleEditOffer = () => {
		if (selectedClientOf.length) {
			setIsLoading(true);
			const modifiedOffer = modifyCreateOffer({
				...editOffer,
				clientOf: selectedClientOf,
			});

			const onSuccess = () => {
				dispatch(
					showSnackBar({
						text: `Offer ${editOffer.name} edited successfully`,
						severity: "success",
						duration: 3000,
					}),
				);
				handleClose();
			};

			dispatch(
				editOfferStore({
					offer: modifiedOffer,
					onSuccess,
					onFinish: () => setIsLoading(false),
				}),
			);
		} else {
			setError("Client Of is required");
		}
	};

	const onChangeSelectedItems = (items) => {
		if (error) {
			setError("");
		}
		setSelectedClientOf(items);
	};

	if (!catalogs?.clientOf) {
		return null;
	}

	return (
		<ModalContainer
			title="Edit Offer"
			handleClose={handleClose}
			containerStyle={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flex: 1,
				}}
			>
				<Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: "16px",
						}}
					>
						<Typography
							sx={{ fontSize: 16, lineHeight: "19px", color: "#030229" }}
						>
							Client Of
						</Typography>
						<ButtonBase disableRipple onClick={handleReset}>
							<Typography
								sx={{
									fontSize: 12,
									fontWeight: "600",
									lineHeight: "14px",
									color: "primary.main",
									textDecorationLine: "underline",
								}}
							>
								Reset
							</Typography>
						</ButtonBase>
					</Box>
					{selectedClientOf.length ? (
						<Box display="flex" flexWrap="wrap" gap={"5px"} sx={{ mb: "16px" }}>
							{selectedClientOf.map((selectedClientOfItem) => (
								<SelectedItem
									key={selectedClientOfItem.id}
									name={selectedClientOfItem.name}
									id={selectedClientOfItem.id}
									onRemove={(id) =>
										setSelectedClientOf((prev) =>
											prev.filter((item) => item.id !== id),
										)
									}
								/>
							))}
						</Box>
					) : null}
					<MultiSelectWithSearch
						data={catalogs.clientOf}
						selectedItems={selectedClientOf}
						setSelectedItems={onChangeSelectedItems}
					/>
					{error && (
						<Typography
							sx={{
								fontSize: 12,
								lineHeight: "14px",
								mt: "2px",
								color: "error.main",
								ml: "10px",
							}}
						>
							{error}
						</Typography>
					)}
				</Box>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<LoadingButton
						sx={{
							height: "45px",
							mt: "8px",
							borderRadius: "10px",
							width: "238px",
						}}
						variant="contained"
						onClick={handleEditOffer}
						loading={isLoading}
					>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "600",
								fontSize: 16,
								textTransform: "capitalize",
							}}
						>
							Edit Offer
						</Typography>
					</LoadingButton>
				</Box>
			</Box>
		</ModalContainer>
	);
};
