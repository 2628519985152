import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Icon } from "./Icon";

export const SelectedItem = ({ name, id, onRemove }) => {
	return (
		<Box
			data-testid={`${name}-selected`}
			sx={{
				borderRadius: "5px",
				pl: "5px",
				backgroundColor: "#e9ecf0",
				height: "25px",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Typography
				sx={{ fontSize: 16, lineHeight: "19px", color: "black.dark" }}
			>
				{name}
			</Typography>
			<ButtonBase
				disableRipple
				sx={{
					px: "5px",
					height: "100%",
					borderTopRightRadius: "5px",
					borderBottomRightRadius: "5px",
				}}
				onClick={() => onRemove(id)}
			>
				<Icon name="closePrimary" style={{ width: "8px", height: "8px" }} />
			</ButtonBase>
		</Box>
	);
};
