import React from "react";
import { Skeleton } from "@mui/material";

export const SkeletonCompetitorsLogo = () =>
	Array.from({ length: 5 }).map((_, i) => (
		<Skeleton
			key={i}
			variant="circular"
			animation="wave"
			width={"204px"}
			height={"204px"}
		/>
	));
