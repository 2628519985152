import React from "react";
import Box from "@mui/material/Box";

export const AuthSwiperPagination = ({ steps, activeStep }) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{Array.from({ length: steps }, (_, index) => (
				<Box
					key={index}
					sx={{
						width: activeStep === index ? 35 : 16,
						height: 7,
						backgroundColor:
							activeStep === index ? "#9ACDC1" : "rgba(154, 205, 193, 0.3)",
						borderRadius: "100px",
						margin: "0 6px",
						transition: "all 0.3s ease",
					}}
				/>
			))}
		</Box>
	);
};
