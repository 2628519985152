import { ReactComponent as DollarIcon } from "./dollar.svg";
import { ReactComponent as ShoppingIcon } from "./shopping.svg";
import { ReactComponent as ListIcon } from "./list.svg";
import { ReactComponent as PlusOfferIcon } from "./plusOffer.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as ClosePrimaryIcon } from "./closePrimary.svg";
import { ReactComponent as CloseBlackIcon } from "./closeBlack.svg";
import { ReactComponent as CalendarIcon } from "./calendar.svg";
import { ReactComponent as ClockIcon } from "./clock.svg";
import { ReactComponent as CheckboxIcon } from "./checkbox.svg";
import { ReactComponent as RiyadhIcon } from "./riyadh.svg";
import { ReactComponent as JeddahIcon } from "./jeddah.svg";
import { ReactComponent as EasternProvIcon } from "./easternProv.svg";
import { ReactComponent as SaudiArabiaIcon } from "./saudiArabia.svg";
import { ReactComponent as ArrowDownMultiSelectIcon } from "./arrowDownMultiSelect.svg";
import { ReactComponent as MinusSelectIcon } from "./minusSelect.svg";
import { ReactComponent as PlusSelectIcon } from "./plusSelect.svg";
import { ReactComponent as ArrowDownTableColumnIcon } from "./arrowDownTableColumn.svg";
import { ReactComponent as FilterIcon } from "./filter.svg";
import { ReactComponent as EyeIcon } from "./eye.svg";
import { ReactComponent as EyeOffIcon } from "./eyeOff.svg";
import { ReactComponent as ArrowDownSwitchBrandIcon } from "./arrowDownSwitchBrand.svg";
import { ReactComponent as SettingsIcon } from "./settings.svg";
import { ReactComponent as SupportIcon } from "./support.svg";
import { ReactComponent as SignOutIcon } from "./signOut.svg";
import { ReactComponent as BackButtonIcon } from "./backButton.svg";
import { ReactComponent as CameraIcon } from "./camera.svg";
import { ReactComponent as DragItemIcon } from "./dragItem.svg";
import { ReactComponent as PdfIcon } from "./pdf.svg";
import { ReactComponent as CheckboxBoldIcon } from "./checkboxBold.svg";
import { ReactComponent as InfoIcon } from "./info.svg";

export const icons = {
	checkboxBold: CheckboxBoldIcon,
	pdf: PdfIcon,
	dragItem: DragItemIcon,
	camera: CameraIcon,
	backButton: BackButtonIcon,
	dollar: DollarIcon,
	shopping: ShoppingIcon,
	list: ListIcon,
	plusOffer: PlusOfferIcon,
	search: SearchIcon,
	closePrimary: ClosePrimaryIcon,
	closeBlack: CloseBlackIcon,
	calendar: CalendarIcon,
	clock: ClockIcon,
	checkbox: CheckboxIcon,
	riyadh: RiyadhIcon,
	jeddah: JeddahIcon,
	easternProv: EasternProvIcon,
	saudiArabia: SaudiArabiaIcon,
	arrowDownMultiSelect: ArrowDownMultiSelectIcon,
	minusSelect: MinusSelectIcon,
	plusSelect: PlusSelectIcon,
	arrowDownTableColumn: ArrowDownTableColumnIcon,
	filter: FilterIcon,
	eye: EyeIcon,
	eyeOff: EyeOffIcon,
	arrowDownSwitchBrand: ArrowDownSwitchBrandIcon,
	settings: SettingsIcon,
	support: SupportIcon,
	signOut: SignOutIcon,
	info: InfoIcon,
	default: DollarIcon,
};
