import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Box, Typography } from "@mui/material";
import { TextInputNew } from "components/TextInputNew";
import { useDispatch, useSelector } from "react-redux";
import {
	createFirstBrand,
	fetchCreateBrandData,
	resetCreateBrandPdfCertificate,
	uploadCertificate,
} from "../store/brandsSlice";
import { showSnackBar } from "../store/uiSlice";
import { LoadingButton } from "@mui/lab";
import { LogoUploader } from "components/LogoUploader";
import { MultiSelectCompetitors } from "components/MultiSelectCompetitors";
import ReorderCompetitors from "components/ReorderCompetitors";
import { CertificateUpload } from "components/CertificateUpload";
import { Select } from "./Select";
import { Icon } from "./Icon";
import {
	setBrandCreated,
	setEditBrand,
	setSignUpBrandData,
	setSignUpTop5Competitors,
} from "store/authSlice";
import { validateForm } from "utils/validate";

const initialErrors = {
	name: [],
	domain: [],
	categoryId: [],
};

export const CreateFirstBrand = ({ setCurrentStep }) => {
	const dispatch = useDispatch();
	const {
		categories,
		isLoadingBrand,
		allCompetitors,
		isLoadingCreateBrandData,
		isLoadingCertificate,
	} = useSelector((state) => state.brands);
	const { signUpData } = useSelector((state) => state.auth);
	const certificatePdfNumber = useSelector(
		(state) => state.brands.createBrandPdf.certificate,
	);

	const newBrand = signUpData.brand;
	const userId = signUpData.userId;
	const isBrandCreated = signUpData.isBrandCreated;
	const [errors, setErrors] = useState(initialErrors);
	const [errorLogo, setErrorLogo] = useState("");
	const [errorCertificate, setErrorCertificate] = useState("");
	const [touched, setTouched] = useState({});
	const [uploadCertificateProgress, setUploadCertificateProgress] = useState(
		isBrandCreated ? 100 : 0,
	);

	const isCertificateUploading = useMemo(
		() =>
			isLoadingCertificate ||
			(uploadCertificateProgress > 0 && uploadCertificateProgress < 100),
		[isLoadingCertificate, uploadCertificateProgress],
	);

	const abortControllerRef = useRef(null);

	const handleContinue = () => {
		setErrors(initialErrors);
		setCurrentStep(2);
	};

	const handleCreate = (e) => {
		e.preventDefault();

		const isFormValid = handleSubmitValidation();

		// if user pressed on continue we will show all errors
		setTouched({ domain: true, name: true, categoryId: true });

		if (!isFormValid) {
			return;
		}

		const onSuccess = () => {
			dispatch(
				showSnackBar({
					text: `Brand ${newBrand.name} created successfully`,
					severity: "success",
					duration: 3000,
				}),
			);
			handleContinue();

			if (isBrandCreated) {
				dispatch(setEditBrand({}));
			} else {
				dispatch(setBrandCreated(true));
			}
		};

		let brandData = {};

		if (isBrandCreated) {
			brandData = {
				...signUpData.editBrand,
				top5competitors: signUpData.top5competitors
					.map((competitor) => competitor.id)
					.join(","),
			};

			if ("picture" in brandData) {
				brandData.picture = brandData.picture.file;
			}
		} else {
			brandData = {
				...newBrand,
				picture: newBrand.picture.file,
				top5competitors: signUpData.top5competitors
					.map((competitor) => competitor.id)
					.join(","),
			};
		}

		brandData.certificatePdf = certificatePdfNumber;

		dispatch(
			createFirstBrand({
				userId,
				brand: brandData,
				onSuccess,
			}),
		);
	};

	const handleSubmitValidation = () => {
		const newErrors = validateForm(newBrand, "createBrand");

		setErrors(newErrors);

		if (!newBrand?.picture) {
			setErrorLogo("Please upload the brand logo");
		}

		if (!newBrand?.certificatePdf) {
			setErrorCertificate(
				"Please upload the commercial registration certificate",
			);
		}

		const noErrors = Object.keys(newErrors).every(
			(key) => !newErrors[key].length,
		);

		if (noErrors && newBrand?.certificatePdf && newBrand?.picture) {
			return true;
		}

		return false;
	};

	const handleValidation = (newBrand) => {
		const newErrors = validateForm(newBrand, "createBrand");

		// Include all fields in filteredErrors, but only include errors for fields that have been touched
		const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
			return {
				...obj,
				[key]: touched[key] ? newErrors[key] : [],
			};
		}, {});

		setErrors(filteredErrors);
	};

	const handleChangeNewBrand = useCallback(
		(e) => {
			const { name, value } = e.target;

			setTouched((prev) => ({
				...prev,
				[name]: true,
			}));

			if (isBrandCreated) {
				dispatch(setEditBrand({ ...signUpData.editBrand, [name]: value }));
			}

			dispatch(setSignUpBrandData({ ...newBrand, [name]: value }));
		},
		[signUpData, newBrand, dispatch, setTouched],
	);

	useEffect(() => {
		if (!categories.length && !allCompetitors.length) {
			dispatch(fetchCreateBrandData());
		}
	}, [dispatch]);

	const handleReorderCompetitors = (startIndex, endIndex) => {
		const reorderedCompetitors = Array.from(signUpData.top5competitors);
		const [removed] = reorderedCompetitors.splice(startIndex, 1);
		reorderedCompetitors.splice(endIndex, 0, removed);
		dispatch(setSignUpTop5Competitors(reorderedCompetitors));
	};

	const handleChangeSelectedCompetitors = (newSelectedCompetitors) => {
		if (newSelectedCompetitors.length > 5) {
			return;
		}

		dispatch(setSignUpTop5Competitors(newSelectedCompetitors));
	};

	const handleChangeCertificate = (newCertificate) => {
		handleChangeNewBrand({
			target: { name: "certificatePdf", value: newCertificate },
		});
		setUploadCertificateProgress(0);

		if (!newCertificate) {
			dispatch(resetCreateBrandPdfCertificate());
			return;
		}

		if (errorCertificate) {
			setErrorCertificate("");
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		dispatch(
			uploadCertificate({
				certificate: newCertificate,
				signal: abortController.signal,
				onUploadProgress: (progress) => {
					setUploadCertificateProgress(progress);
				},
			}),
		);
	};

	const handleChangeLogo = (logo) => {
		if (errorLogo) {
			setErrorLogo("");
		}

		handleChangeNewBrand({
			target: { name: "picture", value: logo },
		});
	};

	useEffect(() => {
		handleValidation(newBrand);
	}, [newBrand]);

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-start",
					mb: "32px",
				}}
			>
				<Box
					sx={{
						"display": "inline-flex",
						"&:hover": { cursor: "pointer" },
					}}
					onClick={() => setCurrentStep(0)}
				>
					<Icon name="backButton" style={{ height: "40px", width: "40px" }} />
				</Box>
			</Box>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontWeight: "700",
					fontSize: "40px",
					lineHeight: "44px",
					color: "#232323",
					letterSpacing: "-0.04em",
					mb: "12px",
				}}
			>
				Add Brand
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#969696",
					mb: "32px",
				}}
			>
				Sign up your brand with Mthmr
			</Typography>
			<Box component="form" onSubmit={handleCreate}>
				<Box sx={{ mb: "24px" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<LogoUploader
							logo={newBrand.picture}
							setLogo={handleChangeLogo}
							containerStyle={{ mr: "24px", height: "70px", width: "70px" }}
							isError={errorLogo}
						/>
						<TextInputNew
							label="Brand Name"
							name="name"
							value={newBrand.name}
							onChange={handleChangeNewBrand}
							containerStyle={{ flex: 1 }}
							isError={!!errors.name.length}
						/>
					</Box>
					{[errorLogo, ...errors.name].map((error) => (
						<Box
							key={`name-${error}`}
							sx={{
								display: "flex",
								mt: "5px",
							}}
						>
							<Typography
								sx={{
									fontSize: "14px",
									lineHeight: "17px",
									fontWeight: "500",
									color: "#EF5457",
								}}
							>
								{error}
							</Typography>
						</Box>
					))}
				</Box>
				<CertificateUpload
					uploadProgress={uploadCertificateProgress}
					certificate={newBrand.certificatePdf}
					onChangeCertificate={handleChangeCertificate}
					containerStyle={{ mb: "24px" }}
					error={errorCertificate}
					abortControllerRef={abortControllerRef}
				/>
				<TextInputNew
					label="Brand Domain"
					name="domain"
					value={newBrand.domain}
					onChange={handleChangeNewBrand}
					containerStyle={{ mb: "24px" }}
					errors={errors.domain}
				/>
				<Select
					isLoading={isLoadingCreateBrandData}
					items={categories}
					value={newBrand.categoryId}
					onChange={handleChangeNewBrand}
					name={"categoryId"}
					label={"Brand Category"}
					errors={errors.categoryId}
				/>
				<MultiSelectCompetitors
					competitors={allCompetitors}
					isLoading={isLoadingCreateBrandData}
					name={"Top 5 Competitors"}
					selectedItems={signUpData.top5competitors}
					setSelectedItems={handleChangeSelectedCompetitors}
				/>
				<ReorderCompetitors
					competitors={signUpData.top5competitors}
					setCompetitors={handleChangeSelectedCompetitors}
					onReorder={handleReorderCompetitors}
					containerStyle={{ mt: "24px" }}
				/>
				<Box sx={{ display: "flex", justifyContent: "flex-end", mt: "32px" }}>
					<LoadingButton
						sx={{
							height: "54px",
							borderRadius: "10px",
							width: "100%",
						}}
						loading={isLoadingBrand || isCertificateUploading}
						variant="contained"
						type="submit"
					>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontWeight: "600",
								fontSize: "18px",
								lineHeight: "22px",
								textTransform: "none",
								letterSpacing: "-0.01em",
							}}
						>
							Continue
						</Typography>
					</LoadingButton>
				</Box>
			</Box>
		</Box>
	);
};
