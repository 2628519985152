import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetCompetitors } from "../api";
import { errorHandler } from "../utils/errorHandling";

const competitorsInitialState = {
	competitors: [],
};

export const fetchCompetitors = createAsyncThunk(
	"competitors/fetchCompetitors",
	async ({ params, onFinish }) => {
		try {
			const response = await apiGetCompetitors(params);
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

const competitorsSlice = createSlice({
	name: "competitors",
	initialState: competitorsInitialState,
	reducers: {
		setCompetitors: (state, { payload }) => {
			state.competitors = payload;
		},
		resetCompetitors: (state) => {
			state.competitors = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCompetitors.fulfilled, (state, { payload }) => {
			state.competitors = payload;
		});
	},
});

export const competitorsReducer = competitorsSlice.reducer;
export const { setCompetitors, resetCompetitors } = competitorsSlice.actions;
