import React from "react";
import { Grid } from "@mui/material";
import { SkeletonOffer } from "./SkeletonOffer";

export const SkeletonOffers = ({ count }) =>
	Array.from({ length: count }).map((_, index) => (
		<Grid item xs={4} key={`skeleton-offer-${index}`}>
			<SkeletonOffer />
		</Grid>
	));
