import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import OtpTextInput from "./OtpTextInput";
import { Icon } from "./Icon";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { resendEmail, verifyEmail } from "store/authSlice";

const OTP_LENGTH = 5;

export default function VerifyEmail({
	setAccessToken,
	setCurrentStep,
	verificationCode,
	setVerificationCode,
}) {
	const dispatch = useDispatch();

	const { signUpData, isLoadingVerifyEmail } = useSelector(
		(state) => state.auth,
	);

	const userId = signUpData.userId;
	const email = signUpData.user.email;

	const handleVerifyEmail = async () => {
		const verificationCodeString = verificationCode.toString("");

		const onSuccess = (accessToken) => {
			setAccessToken(accessToken);
			setCurrentStep(3);
		};

		dispatch(
			verifyEmail({
				data: { userId, code: verificationCodeString },
				onSuccess,
			}),
		);
	};

	const handleResendEmail = async () => {
		dispatch(resendEmail(userId));
	};

	const handleChangeEmail = () => {
		setCurrentStep(0);
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-start",
					mb: "32px",
				}}
			>
				<Box
					sx={{
						"display": "inline-flex",
						"&:hover": { cursor: "pointer" },
					}}
					onClick={() => setCurrentStep(1)}
				>
					<Icon name="backButton" style={{ height: "40px", width: "40px" }} />
				</Box>
			</Box>

			<Typography
				sx={{
					fontFamily: "Inter",
					fontWeight: "700",
					fontSize: "40px",
					lineHeight: "44px",
					color: "#232323",
					letterSpacing: "-0.04em",
					mb: "12px",
				}}
			>
				Verify your email
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#969696",
					mb: "32px",
				}}
			>
				A verification code has been sent to{" "}
				<Box
					sx={{
						color: "primary.dark",
					}}
					component="span"
				>
					{email}
				</Box>
			</Typography>
			<OtpTextInput
				number={OTP_LENGTH}
				name="verificationCode"
				containerStyle={{ mb: "32px" }}
				setVerificationCode={setVerificationCode}
			/>

			<LoadingButton
				variant="contained"
				color="primary"
				fullWidth
				disabled={verificationCode.length !== OTP_LENGTH}
				onClick={handleVerifyEmail}
				loading={isLoadingVerifyEmail}
				sx={{
					height: "54px",
					borderRadius: "10px",
					mb: "32px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontWeight: "600",
						fontSize: "18px",
						lineHeight: "22px",
						letterSpacing: "-0.01em",
						textTransform: "none",
					}}
				>
					Verify
				</Typography>
			</LoadingButton>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#6C6C6C",
					textAlign: "center",
					mb: "32px",
				}}
			>
				Didn&apos;t receive the email?{" "}
				<Box
					sx={{
						"fontWeight": "600",
						"position": "relative",
						"display": "inline-block",
						"::after": {
							content: '""',
							position: "absolute",
							left: 0,
							bottom: "2px",
							width: "100%",
							height: "1.3px",
							backgroundColor: "primary.dark",
						},
						"color": "primary.dark",
						"&:hover": {
							cursor: "pointer",
						},
					}}
					component="span"
					onClick={handleResendEmail}
				>
					Resend email
				</Box>
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#6C6C6C",
					textAlign: "center",
				}}
			>
				Incorrect email?{" "}
				<Box
					sx={{
						"fontWeight": "600",
						"position": "relative",
						"display": "inline-block",
						"::after": {
							content: '""',
							position: "absolute",
							left: 0,
							bottom: "2px",
							width: "100%",
							height: "1.3px",
							backgroundColor: "primary.dark",
						},
						"color": "primary.dark",
						"&:hover": {
							cursor: "pointer",
						},
					}}
					component="span"
					onClick={handleChangeEmail}
				>
					Change email
				</Box>
			</Typography>
		</Box>
	);
}
