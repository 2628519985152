import React, { useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Box, ButtonBase, LinearProgress, Typography } from "@mui/material";
import { Icon } from "./Icon";
import { bytesToMegabytes, megabytesToBytes } from "utils/fileUtils";

const PDF_MAXIMUM_SIZE = 10; // MB

export const CertificateUpload = ({
	certificate,
	onChangeCertificate,
	uploadProgress,
	showLabel = true,
	containerStyle = {},
	error = "",
	abortControllerRef = null,
}) => {
	const fileInputRef = useRef(null);

	const onDrop = (acceptedFiles, rejectedFiles) => {
		// Check for rejected files by size and file type
		if (rejectedFiles.length > 0) {
			const rejectedFile = rejectedFiles[0];
			if (rejectedFile.file.size > megabytesToBytes(PDF_MAXIMUM_SIZE)) {
				alert(`Supports: PDF up to ${PDF_MAXIMUM_SIZE}MB`);
			} else {
				alert("Only PDF files are allowed");
			}
			return;
		}

		if (acceptedFiles.length === 0) {
			return;
		}

		const file = acceptedFiles[0];
		onChangeCertificate(file);
	};

	const handleRemovePdf = () => {
		onChangeCertificate(null);

		if (fileInputRef?.current) {
			fileInputRef.current.value = "";
		}

		if (abortControllerRef?.current) {
			abortControllerRef.current.abort();
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"application/pdf": [".pdf"],
		},
		maxFiles: 1,
		maxSize: megabytesToBytes(PDF_MAXIMUM_SIZE), // 10MB
	});

	const containerBorder = useMemo(() => {
		const color = error ? "#EF5457" : "#D9D9D9";

		if (certificate || error) {
			return `1.5px solid ${color}`;
		}

		return `1.5px dashed ${color}`;
	}, [certificate, error]);

	return (
		<Box sx={containerStyle}>
			<Box
				sx={{
					p: "16px",
					border: containerBorder,
					borderRadius: "10px",
					textAlign: "center",
					position: "relative",
					height: "90px",
				}}
			>
				<div
					{...getRootProps()}
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<input
						{...getInputProps()}
						ref={fileInputRef}
						accept="application/pdf"
						style={{
							opacity: 0,
							position: "absolute",
							height: "100%",
							width: "100%",
						}}
					/>
					{showLabel && (
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "14px",
								lineHeight: "21px",
								color: error ? "#EF5457" : "#9A9A9A",
								backgroundColor: "white.main",
								zIndex: 2,
								position: "absolute",
								top: "-2px",
								transform: "translateY(-50%)",
								left: "12px",
								px: "4px",
								fontWeight: "400",
								textShadow: "0 0 0.5px #9A9A9A",
							}}
						>
							Commercial Registration Certificate
						</Typography>
					)}
					{certificate ? (
						<Box sx={{ width: "100%", position: "relative" }}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									mb: "5px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										overflow: "hidden",
										flexGrow: 1,
										minWidth: 0,
									}}
								>
									<Icon
										name="pdf"
										style={{
											width: "38px",
											height: "38px",
											marginRight: "5px",
										}}
									/>
									<Box sx={{ textAlign: "left", flexGrow: 1, minWidth: 0 }}>
										<Typography
											sx={{
												fontFamily: "Inter",
												fontSize: "14px",
												lineHeight: "17px",
												mb: "5px",
												color: "#2C2C2C",
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
												paddingRight: "24px",
											}}
										>
											{certificate.name}
										</Typography>
										<Typography
											sx={{
												fontFamily: "Inter",
												fontSize: "12px",
												lineHeight: "15px",
												color: "#2C2C2C",
											}}
										>
											{certificate.size && bytesToMegabytes(certificate.size)}{" "}
											MB
										</Typography>
									</Box>
								</Box>
								<ButtonBase
									sx={{ position: "absolute", right: 0, top: "14px" }}
									onClick={handleRemovePdf}
									disableRipple
								>
									<Icon
										name="closeBlack"
										style={{ width: "10px", height: "10px" }}
									/>
								</ButtonBase>
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										p: "2px",
										backgroundColor: "#f5f5f7",
										borderRadius: "10px",
										width: "100%",
										mr: "5px",
									}}
								>
									<LinearProgress
										variant="determinate"
										value={uploadProgress}
										sx={{
											height: "4px",
											borderRadius: "10px",
											backgroundColor: "#f5f5f7",
										}}
									/>
								</Box>
								<Typography
									sx={{
										fontSize: "12px",
										lineHeight: "15px",
										color: "#232323",
									}}
								>
									{uploadProgress}%
								</Typography>
							</Box>
						</Box>
					) : (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "14px",
									fontWeight: "500",
									lineHeight: "21px",
									color: "#9A9A9A",
									mb: "5px",
								}}
							>
								{`Drag and drop or `}
								<Box
									sx={{
										"position": "relative",
										"display": "inline-block",
										"color": "primary.dark",
										"::after": {
											content: '""',
											position: "absolute",
											left: 0,
											bottom: "1px",
											width: "100%",
											height: "1px",
											backgroundColor: "primary.dark",
										},
										"pointerEvents": "none",
									}}
									component="span"
								>
									click to upload
								</Box>
							</Typography>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "12px",
									lineHeight: "15px",
									color: "#9A9A9A",
								}}
							>
								{`Supports: PDF up to ${PDF_MAXIMUM_SIZE}MB`}
							</Typography>
						</Box>
					)}
				</div>
			</Box>
			{error && (
				<Box
					sx={{
						display: "flex",
						mt: "5px",
					}}
				>
					<Typography
						sx={{
							fontSize: "14px",
							lineHeight: "17px",
							fontWeight: "500",
							color: "#EF5457",
						}}
					>
						{error}
					</Typography>
				</Box>
			)}
		</Box>
	);
};
