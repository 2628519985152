import React from "react";
import { Skeleton } from "@mui/material";

export const SkeletonCompetitorsTable = () =>
	Array.from({ length: 10 }).map((_, index) => (
		<Skeleton
			key={`skeleton-competitors-${index}`}
			variant="rounded"
			height={"53px"}
			sx={{ borderRadius: "2px", mb: "2px" }}
			width={"100%"}
		/>
	));
