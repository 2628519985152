import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import { TextInputNew } from "./TextInputNew";
import { Checkbox, FormControlLabel, Link } from "@mui/material";
import { OrDivider } from "./OrDivider";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkSignUpEmail, setSignUpData, signUp } from "store/authSlice";
import { validateForm } from "utils/validate";
import { LoadingButton } from "@mui/lab";

const ERROR_EMAIL_EXISTS = "An account with this email address already exists";

export default function SignUp({ switchToSignIn, setCurrentStep }) {
	const dispatch = useDispatch();
	const { signUpData, isLoadingCheckEmail, isLoadingSignUp } = useSelector(
		(state) => state.auth,
	);
	const userId = signUpData.userId;
	const [errors, setErrors] = useState({
		firstName: [],
		lastName: [],
		email: [],
		phone: [],
		password: [],
		confirmPassword: [],
	});
	const [touched, setTouched] = useState({});
	const emailChecked = signUpData.emailChecked;
	// emailChecked is null if email was not checked
	// emailChecked is true if email is available (backend request)
	// emailChecked is false if email is not available (backend request)
	const formData = signUpData.user;

	const handleChange = (event) => {
		const { name, value } = event.target;

		setTouched({
			...touched,
			[name]: true,
		});

		const newSignUpData = { ...signUpData };

		if (name === "email" && emailChecked !== null) {
			newSignUpData.emailChecked = null;
			setErrors((prev) => {
				const emailErrors = prev.email.filter(
					(err) => err !== ERROR_EMAIL_EXISTS,
				);
				return {
					...prev,
					email: emailErrors,
				};
			});
		}

		dispatch(
			setSignUpData({ ...newSignUpData, user: { ...formData, [name]: value } }),
		);
	};

	useEffect(() => {
		if (!formData.email) return;

		const timerId = setTimeout(() => {
			handleCheckEmail(formData.email);
		}, 300);

		return () => {
			clearTimeout(timerId);
		};
	}, [formData.email]);

	const disabledButton = useMemo(() => {
		const allFieldsFilled = Object.keys(formData).every(
			(field) => formData[field].length,
		);

		const isErrors = Object.keys(errors).some(
			(field) => errors[field].length !== 0,
		);

		return (
			!allFieldsFilled ||
			!signUpData.checkedTermsConditions ||
			!emailChecked ||
			isErrors
		);
	}, [formData, signUpData.checkedTermsConditions, emailChecked, errors]);

	const handleCheckboxChange = (event) => {
		dispatch(
			setSignUpData({
				...signUpData,
				checkedTermsConditions: event.target.checked,
			}),
		);
	};

	const handleCheckEmail = (email) => {
		dispatch(checkSignUpEmail({ email }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const userData = { ...formData };
		delete userData.confirmPassword;

		if (userId) {
			userData.userId = userId;
		}

		const onSuccess = () => {
			setCurrentStep(1);
		};

		dispatch(signUp({ userData, onSuccess }));
	};

	const handleValidation = (form) => {
		const newErrors = validateForm(form, "signUpUser");

		// Include all fields in filteredErrors, but only include errors for fields that have been touched
		const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
			return {
				...obj,
				[key]: touched[key] ? newErrors[key] : [],
			};
		}, {});

		setErrors(filteredErrors);
	};

	useEffect(() => {
		handleValidation(formData);
	}, [formData]);

	useEffect(() => {
		if (touched?.email && emailChecked !== null) {
			if (emailChecked) {
				setErrors((prev) => {
					// Remove ERROR_EMAIL_EXISTS from errors
					const newEmailErrors = prev.email.filter(
						(err) => err !== ERROR_EMAIL_EXISTS,
					);

					return {
						...prev,
						email: newEmailErrors,
					};
				});
			} else {
				setErrors((prev) => ({
					...prev,
					email: [ERROR_EMAIL_EXISTS],
				}));
			}
		}
	}, [emailChecked, touched]);

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontWeight: "700",
					fontSize: "40px",
					lineHeight: "44px",
					color: "#232323",
					letterSpacing: "-0.04em",
					mb: "12px",
				}}
			>
				Sign up
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#969696",
					mb: "32px",
				}}
			>
				Sign up to enjoy the features of Mthmr
			</Typography>
			<form onSubmit={handleSubmit}>
				<TextInputNew
					label="First Name"
					name="firstName"
					value={formData.firstName}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.firstName}
				/>
				<TextInputNew
					label="Last Name"
					name="lastName"
					value={formData.lastName}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.lastName}
				/>
				<TextInputNew
					label="Business Email"
					name="email"
					value={formData.email}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					isLoading={isLoadingCheckEmail}
					errors={errors.email}
				/>
				<TextInputNew
					label="Phone Number"
					name="phone"
					value={formData.phone}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.phone}
				/>
				<TextInputNew
					label="Password"
					name="password"
					password
					value={formData.password}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.password}
				/>
				<TextInputNew
					label="Confirm Password"
					name="confirmPassword"
					password
					value={formData.confirmPassword}
					onChange={handleChange}
					errors={errors.confirmPassword}
				/>
				<FormControlLabel
					sx={{ mt: "20px" }}
					control={
						<Checkbox
							checked={signUpData.checkedTermsConditions}
							onChange={handleCheckboxChange}
							name="checked"
							color="primary"
						/>
					}
					label={
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "18px",
								fontWeight: "400",
								color: "#6C6C6C",
							}}
						>
							I agree to the{" "}
							<Box
								sx={{
									"color": "primary.dark",
									"position": "relative",
									"display": "inline-block",
									"::after": {
										content: '""',
										position: "absolute",
										left: 0,
										bottom: "2px",
										width: "100%",
										height: "1.3px",
										backgroundColor: "primary.dark",
									},
									"fontWeight": "600",
									"&:hover": {
										cursor: "pointer",
									},
								}}
								component="span"
							>
								<Link
									href="https://www.mthmr.com/Terms&Conditions"
									target="_blank"
									rel="noopener"
									color={"primary.dark"}
									style={{ textDecoration: "none" }}
								>
									Terms & Conditions
								</Link>
							</Box>
						</Typography>
					}
				/>
				<LoadingButton
					type="submit"
					variant="contained"
					color="primary"
					fullWidth
					sx={{
						height: "54px",
						mt: "20px",
						borderRadius: "10px",
						mb: "20px",
					}}
					loading={isLoadingSignUp}
					disabled={disabledButton}
				>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontWeight: "600",
							fontSize: "18px",
							lineHeight: "22px",
							letterSpacing: "-0.01em",
							textTransform: "none",
						}}
					>
						Continue
					</Typography>
				</LoadingButton>
			</form>
			<OrDivider containerStyle={{ mb: "32px" }} />

			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#6C6C6C",
					textAlign: "center",
				}}
			>
				{`Already have an account? `}
				<Box
					sx={{
						"fontWeight": "600",
						"position": "relative",
						"display": "inline-block",
						"::after": {
							content: '""',
							position: "absolute",
							left: 0,
							bottom: "2px",
							width: "100%",
							height: "1.3px",
							backgroundColor: "primary.dark",
						},
						"color": "primary.dark",
						"&:hover": {
							cursor: "pointer",
						},
					}}
					onClick={switchToSignIn}
					component="span"
				>
					Log in
				</Box>
			</Typography>
		</Box>
	);
}
