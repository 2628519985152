import React, { Component } from "react";
import styles from "../styles";
import { defaultStaticRanges } from "../defaultRanges";
import cx from "classnames";
import { Box, ButtonBase } from "@mui/material";

class DefinedRange extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rangeOffset: 0,
			focusedInput: -1,
		};
	}

	handleRangeChange = (range) => {
		const { onChange, ranges, focusedRange } = this.props;
		const selectedRange = ranges[focusedRange[0]];
		if (!onChange || !selectedRange) return;
		onChange({
			[selectedRange.key || `range${focusedRange[0] + 1}`]: {
				...selectedRange,
				...range,
			},
		});
	};

	getRangeOptionValue(option) {
		const { ranges = [], focusedRange = [] } = this.props;

		if (typeof option.getCurrentValue !== "function") {
			return "";
		}

		const selectedRange = ranges[focusedRange[0]] || {};
		return option.getCurrentValue(selectedRange) || "";
	}

	getSelectedRange(ranges, staticRange) {
		const focusedRangeIndex = ranges.findIndex((range) => {
			if (!range.startDate || !range.endDate || range.disabled) return false;
			if (staticRange.isSelected(range)) {
				this.props.setSelectedDefinedRange(staticRange);
			}
			return staticRange.isSelected(range);
		});
		const selectedRange = ranges[focusedRangeIndex];
		return { selectedRange, focusedRangeIndex };
	}

	render() {
		const { onPreviewChange, staticRanges, ranges, renderStaticRangeLabel } =
			this.props;

		return (
			<Box
				sx={{
					backgroundColor: "primary.whitePrimary",
					py: "5px",
					px: "30px",
					borderRadius: 10,
				}}
			>
				<Box display="flex" justifyContent="space-between">
					{staticRanges.map((staticRange) => {
						const { selectedRange } = this.getSelectedRange(
							ranges,
							staticRange,
						);
						let labelContent;

						if (staticRange.hasCustomRendering) {
							labelContent = renderStaticRangeLabel(staticRange);
						} else {
							labelContent = staticRange.label;
						}

						return (
							<ButtonBase
								className={cx(styles.staticRange, {
									[styles.staticRangeSelected]: Boolean(selectedRange),
								})}
								data-testid={`date-range-picker-defined-range-${labelContent}`}
								sx={{
									color: selectedRange ? "white.main" : "black.main",
									height: "30px",
									backgroundColor: selectedRange ? "primary.main" : "none",
									px: "14px",
									borderRadius: 10,
									fontSize: 14,
								}}
								key={labelContent}
								onClick={() =>
									this.handleRangeChange(staticRange.range(this.props))
								}
								onFocus={() =>
									onPreviewChange &&
									onPreviewChange(staticRange.range(this.props))
								}
								onMouseOver={() =>
									onPreviewChange &&
									onPreviewChange(staticRange.range(this.props))
								}
								onMouseLeave={() => {
									onPreviewChange && onPreviewChange();
								}}
							>
								{labelContent}
							</ButtonBase>
						);
					})}
				</Box>
			</Box>
		);
	}
}

DefinedRange.defaultProps = {
	staticRanges: defaultStaticRanges,
	ranges: [],
	focusedRange: [0, 0],
};

export default DefinedRange;
