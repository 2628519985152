import React from "react";
import { Box, Input } from "@mui/material";
import { Icon } from "./Icon";
import { colors } from "../styles/colors";

export const SearchField = (props) => (
	<Box
		sx={{
			position: "relative",
			...props.containerStyle,
		}}
	>
		<Input
			{...props}
			disableUnderline={true}
			style={{
				border: "1px solid #8FA0B4",
				borderRadius: "10px",
				width: "100%",
				height: "50px",
				paddingLeft: "20px",
				paddingRight: "50px",
				fontSize: 12,
				backgroundColor: colors.white,
			}}
			inputProps={{
				style: {
					color: "#030229",
				},
			}}
		/>
		<Icon
			name="search"
			style={{ position: "absolute", right: "20px", height: "100%" }}
		/>
	</Box>
);
