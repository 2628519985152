import * as Sentry from "@sentry/react";

export const errorHandler = (e, withoutThrow) => {
	let errorMessage = e?.message || "Server Error";
	if (typeof e?.response?.data?.detail === "string") {
		errorMessage = e?.response?.data?.detail;
	}
	// Lazy load store and showSnackBar to avoid circular dependency
	import("../store").then((module) => {
		const store = module.default;
		import("../store/uiSlice").then((uiModule) => {
			const showSnackBar = uiModule.showSnackBar;
			store.dispatch(
				showSnackBar({
					text: errorMessage,
					severity: "error",
					duration: 5000,
				}),
			);
		});
	});

	Sentry.captureException(e);

	if (!withoutThrow) {
		throw e;
	}
};
