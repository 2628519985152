import React from "react";
import { Grid, Skeleton } from "@mui/material";

export const SkeletonCustomerBehavior = () => (
	<>
		<Grid container spacing={3} sx={{ mb: 3 }}>
			<Grid item xs>
				<Skeleton variant="rounded" height={180} />
			</Grid>
			<Grid item xs>
				<Skeleton variant="rounded" height={180} />
			</Grid>
			<Grid item xs>
				<Skeleton variant="rounded" height={180} />
			</Grid>
		</Grid>
		<Grid container spacing={3} sx={{ mb: 3 }}>
			<Grid item xs={8}>
				<Skeleton variant="rounded" height={292} />
			</Grid>
			<Grid item xs={4}>
				<Skeleton variant="rounded" height={292} />
			</Grid>
		</Grid>
		{/* <Skeleton variant="rounded" height={300} /> */}
	</>
);
