import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { syncBrandIdMiddleware } from "./middleware/reduxMiddleware"; // Adjust the path as needed

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		[
			...getDefaultMiddleware({
				serializableCheck: false,
			}),
			syncBrandIdMiddleware,
		].filter(Boolean),
});

export default store;
