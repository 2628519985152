import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetPerformance, apiGetPerformanceCSV } from "../api";
import fileDownload from "js-file-download";
import { errorHandler } from "../utils/errorHandling";

const performanceInitialState = {
	performance: {},
};

export const fetchPerformance = createAsyncThunk(
	"performance/fetchPerformance",
	async ({ offerId, onLoadingEnd }) => {
		try {
			const response = await apiGetPerformance(offerId);
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			onLoadingEnd();
		}
	},
);

export const fetchPerformanceCSV = createAsyncThunk(
	"performance/fetchPerformanceCSV",
	async ({ fileName, offerId, onLoadingEnd }) => {
		try {
			const response = await apiGetPerformanceCSV(offerId);
			if (response.data) {
				fileDownload(response.data, `${fileName}.csv`);
			}
		} catch (e) {
			errorHandler(e);
		} finally {
			onLoadingEnd();
		}
	},
);

const performanceSlice = createSlice({
	name: "performance",
	initialState: performanceInitialState,
	reducers: {
		resetPerformance: (state) => {
			state.performance = {};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPerformance.fulfilled, (state, { payload }) => {
			state.performance = payload;
		});
	},
});

export const performanceReducer = performanceSlice.reducer;
export const { resetPerformance } = performanceSlice.actions;
