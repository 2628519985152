import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	apiDeleteOffer,
	apiGetCatalogs,
	apiGetOfferDetails,
	apiGetOffers,
	apiPostOffer,
	apiPutOfferChangeStatus,
	apiPutOffer,
} from "../api";
import { errorHandler } from "../utils/errorHandling";
import { modifyEditOffer } from "../utils/offerUtils";

const offersInitialState = {
	offers: [],
	offerDetails: null, // light version of Offer using for Offer Details screen
	offerEdit: null, // full version of Offer with all fields using for Edit Offer screens
	loadingOffers: true,
	catalogs: null,
	newOffer: {},
};

export const fetchOffers = createAsyncThunk(
	"offers/fetchOffers",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoadingOffers(true));
			const response = await apiGetOffers();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingOffers(false));
		}
	},
);

export const fetchOfferEdit = createAsyncThunk(
	"offers/fetchOfferEdit",
	async ({ offerId, onFinish, onSuccess }) => {
		try {
			const response = await apiGetOfferDetails(offerId);
			onSuccess && onSuccess();

			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

export const fetchOfferClone = createAsyncThunk(
	"offers/fetchOfferClone",
	async ({ offerId, onFinish, onSuccess }) => {
		try {
			const response = await apiGetOfferDetails(offerId);
			onSuccess && onSuccess();

			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

export const fetchCatalogs = createAsyncThunk(
	"offers/fetchCatalogs",
	async () => {
		try {
			const response = await apiGetCatalogs();
			return response.data;
		} catch (e) {
			errorHandler(e);
		}
	},
);

export const createOffer = createAsyncThunk(
	"offers/createOffer",
	async ({ offer, onSuccess, onFinish }) => {
		try {
			const response = await apiPostOffer(offer);
			onSuccess && onSuccess();
			return response.data.offer;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

export const editOffer = createAsyncThunk(
	"offers/editOffer",
	async ({ offer, onSuccess, onFinish }) => {
		try {
			const response = await apiPutOffer(offer);
			onSuccess && onSuccess();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

export const deleteOffer = createAsyncThunk(
	"offers/deleteOffer",
	async ({ offerId, onSuccess, onFinish }) => {
		try {
			await apiDeleteOffer(offerId);

			onSuccess && onSuccess();
			return offerId;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

export const changeStatusOffer = createAsyncThunk(
	"offers/changeStatusOffer",
	async ({ offerId, status, onSuccess, onFinish }) => {
		try {
			const response = await apiPutOfferChangeStatus(offerId, status);
			onSuccess && onSuccess();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

const offersSlice = createSlice({
	name: "offers",
	initialState: offersInitialState,
	reducers: {
		setNewOffer: (state, { payload }) => {
			state.newOffer = payload;
		},
		setEditOffer: (state, { payload }) => {
			state.offerEdit = payload;
		},
		setLoadingOffers: (state, { payload }) => {
			state.loadingOffers = payload;
		},
		setOfferDetails: (state, { payload }) => {
			state.offerDetails = payload.offer;
			payload.onFinish();
		},
		resetOffers: (state) => {
			state.offers = [];
			state.catalogs = null;
			state.newOffer = {};
			state.loadingOffers = true;
			state.offerDetails = null;
			state.offerEdit = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchOffers.fulfilled, (state, { payload }) => {
			state.offers = payload;
		});
		builder.addCase(fetchOfferEdit.fulfilled, (state, { payload }) => {
			const modifiedOffer = modifyEditOffer(payload);
			state.offerEdit = modifiedOffer;
		});
		builder.addCase(fetchOfferClone.fulfilled, (state, { payload }) => {
			const modifiedOffer = modifyEditOffer(payload);
			state.newOffer = modifiedOffer;
		});
		builder.addCase(createOffer.fulfilled, (state, { payload }) => {
			state.offers.unshift(payload);
		});
		builder.addCase(editOffer.fulfilled, (state, { payload }) => {
			const { offer: updatedOffer, offerLight: updatedOfferLight } = payload;
			const updatedOffers = state.offers.map((offer) => {
				if (offer.id === updatedOfferLight.id) {
					return { ...offer, ...updatedOfferLight };
				}

				return offer;
			});
			state.offers = updatedOffers;
			state.offerDetails = updatedOffer;
		});
		builder.addCase(fetchCatalogs.fulfilled, (state, { payload }) => {
			state.catalogs = payload;
		});
		builder.addCase(deleteOffer.fulfilled, (state, { payload }) => {
			const filteredOffers = state.offers.filter(
				(offer) => offer.id !== payload,
			);
			state.offers = filteredOffers;
		});
		builder.addCase(changeStatusOffer.fulfilled, (state, { payload }) => {
			const updatedOffers = state.offers.map((offer) => {
				if (offer.id === payload.id) {
					return { ...offer, ...payload };
				}

				return offer;
			});
			state.offers = updatedOffers;
			state.offerDetails = payload;
		});
	},
});

export const offersReducer = offersSlice.reducer;
export const {
	setOfferDetails,
	setNewOffer,
	setEditOffer,
	setLoadingOffers,
	resetOffers,
} = offersSlice.actions;
