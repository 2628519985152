import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "./Icon";

const CIRCLE_SIZE = 40;
const steps = ["Create Account", "Verify Email", "Add Brand", "Subscribe"];

export const SignUpProgressTracker = ({ currentStep, containerStyle }) => (
	<Box
		sx={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			...containerStyle,
		}}
	>
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				width: "100%",
			}}
		>
			{steps.map((step, index) => {
				const status =
					index === currentStep
						? "current"
						: index < currentStep
							? "completed"
							: "inactive";

				return (
					<Box
						key={step}
						sx={{
							display: "flex",
							flex: index !== steps.length - 1 ? 1 : 0,
							flexDirection: "column",
							position: "relative",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: `${CIRCLE_SIZE}px`,
									height: `${CIRCLE_SIZE}px`,
									borderRadius: "50%",
									backgroundColor:
										status === "inactive" ? "#E9E9E9" : "#116E5F",
								}}
							>
								{status === "completed" ? (
									<Icon name={"checkboxBold"} />
								) : (
									<Typography
										sx={{
											fontFamily: "Inter",
											fontWeight: "700",
											fontSize: "12px",
											lineHeight: "18px",
											color: status === "current" ? "#FFFFFF" : "#969696",
										}}
									>
										{index + 1}
									</Typography>
								)}
							</Box>
							{index !== steps.length - 1 ? (
								<Box
									sx={{
										flex: 1,
										flexGrow: 1,
										height: "4px",
										backgroundColor:
											currentStep > index ? "#116E5F" : "#E9E9E9",
										zIndex: 1,
									}}
								/>
							) : null}
						</Box>
						<Typography
							sx={{
								bottom: "-23px", // lineHeight 18px + 5px margin top of Step name Typogoraphy
								position: "absolute",
								transform: `translateX(calc(-50% + ${CIRCLE_SIZE / 2}px))`,
								whiteSpace: "nowrap",
								fontFamily: "Inter",
								fontWeight: "500",
								fontSize: "12px",
								lineHeight: "18px",
								color: status === "inactive" ? "#969696" : "#116E5F",
							}}
						>
							{step}
						</Typography>
					</Box>
				);
			})}
		</Box>
		<Box
			height={{ height: "23px" }} // lineHeight 18px + 5px margin top of Step name Typogoraphy
		/>
	</Box>
);
