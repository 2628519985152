import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Screen } from "../components/Screen";
import { Version } from "../components/Version";
import { OffersList } from "../components/OffersList";
import { Grid, Typography } from "@mui/material";
import { HomeCompetitorsTable } from "../components/Home/HomeCompetitorsTable";
import { HomeClientsLocation } from "../components/Home/HomeClientsLocation";
import { HomeTypeClient } from "../components/Home/HomeTypeClient";
import { CustomerBehaviorLineChart } from "../components/CustomerBehaviorLineChart";
import { chartColors, colors } from "../styles/colors";
import { Icon } from "../components/Icon";
import { fetchHomeData } from "../store/homeSlice";

export const Home = () => {
	const dispatch = useDispatch();
	const home = useSelector(({ home }) => home.home);
	const selectedBrand = useSelector(({ brands }) => brands.selectedBrand);
	const brands = useSelector(({ brands }) => brands.brands);
	const isLoadingHome = useSelector(({ home }) => home.loading);
	const isLoadingBrands = useSelector((state) => state.brands.isLoadingBrands);

	useEffect(() => {
		if (!selectedBrand) {
			return;
		}

		dispatch(fetchHomeData());
	}, [dispatch, selectedBrand]);

	const hasNoBrands = useMemo(
		() => !isLoadingBrands && !brands.length,
		[isLoadingBrands, brands],
	);

	const isLoading = useMemo(
		() => isLoadingHome || isLoadingBrands,
		[isLoadingHome, isLoadingBrands],
	);

	return (
		<Screen title={"Home"}>
			{hasNoBrands ? (
				<Typography
					sx={{
						fontSize: "20px",
						lineHeight: "24px",
						fontWeight: "500",
						textAlign: "center",
					}}
				>
					Please create your first brand in Settings
				</Typography>
			) : (
				<>
					<OffersList
						offers={home.offers}
						loading={isLoading}
						skeletonsCount={3}
						disableOfferDetails
						containerStyle={{ mb: "24px" }}
					/>
					<Grid container spacing={"24px"} sx={{ mb: "24px" }}>
						<Grid item xs={4}>
							<HomeCompetitorsTable
								loading={isLoading}
								competitors={home.competitors}
							/>
						</Grid>
						<Grid item xs={4}>
							<HomeTypeClient loading={isLoading} data={home.typeOfClients} />
						</Grid>
						<Grid item xs={4}>
							<HomeClientsLocation
								loading={isLoading}
								data={home.clientsLocation}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={"24px"}>
						<Grid item xs={4}>
							<CustomerBehaviorLineChart
								data={home.spend}
								color={chartColors.green}
								name="Spend"
								units={"SAR"}
								icon={<Icon name="dollar" style={{ fontSize: 20 }} />}
								iconBackgroundColor={colors.lightLightPrimary}
								loading={isLoading}
							/>
						</Grid>
						<Grid item xs={4}>
							<CustomerBehaviorLineChart
								data={home.noTransactions}
								color={chartColors.purple}
								name="No. of transactions"
								icon={<Icon name="list" style={{ fontSize: 20 }} />}
								iconBackgroundColor={chartColors.lightPurple}
								loading={isLoading}
							/>
						</Grid>
						<Grid item xs={4}>
							<CustomerBehaviorLineChart
								data={home.aov}
								color={chartColors.yellow}
								name="AOV"
								units={"SAR"}
								icon={<Icon name="shopping" style={{ fontSize: 20 }} />}
								iconBackgroundColor={chartColors.lightLightYellow}
								loading={isLoading}
							/>
						</Grid>
					</Grid>
				</>
			)}
			<Version />
			<Outlet />
		</Screen>
	);
};
