import React, { useCallback, useEffect, useState } from "react";
import { SearchField } from "./SearchField";
import { MultiSelectNested } from "./MultiSelectNested";

export const MultiSelectWithSearch = ({
	data,
	selectedItems,
	setSelectedItems,
}) => {
	const [search, setSearch] = useState("");
	const [shownData, setShownData] = useState(data);

	const handleSearch = useCallback(() => {
		const filteredData = searchTree(data, search);
		setShownData(filteredData);
	}, [search]);

	const searchTree = (tree, query) => {
		return tree.reduce((result, node) => {
			// Check if the node's name matches the query
			if (node.name.toLowerCase().includes(query.toLowerCase())) {
				result.push(node);
			} else if (node.children) {
				// Check if the node has children and recursively search them
				const filteredChildren = searchTree(node.children, query);
				if (filteredChildren?.length > 0) {
					// If any child matches the query, include the parent node
					result.push({
						...node,
						children: filteredChildren,
					});
				}
			}

			return result;
		}, []);
	};

	useEffect(() => {
		if (!search.length) {
			setShownData(data);
			return;
		}

		const timeout = setTimeout(handleSearch, 800);
		return () => clearTimeout(timeout);
	}, [handleSearch]);

	const onChangeSearch = (e) => {
		setSearch(e.target.value);
	};
	return (
		<>
			<SearchField
				value={search}
				onChange={onChangeSearch}
				placeholder="Search"
				containerStyle={{ mb: "16px" }}
			/>
			<MultiSelectNested
				data={shownData}
				selectedItems={selectedItems}
				setSelectedItems={setSelectedItems}
			/>
		</>
	);
};
