import { Box, Typography } from "@mui/material";
import React from "react";
import { SwitchBrand } from "./SwitchBrand";

export const Screen = ({
	title = "",
	containerStyle = {},
	hideSeparator = false,
	children,
}) => (
	<Box
		sx={{
			flexGrow: 1,
			backgroundColor: "gray.lightLight",
			height: "100vh",
			overflow: "auto",
			p: "40px 38px 40px 40px",
			...containerStyle,
		}}
	>
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				mb: "16px",
			}}
		>
			<Typography
				sx={{
					fontSize: "24px",
					lineHeight: "29px",
					fontFamily: "Inter",
					fontWeight: "700",
				}}
			>
				{title}
			</Typography>
			<SwitchBrand />
		</Box>
		{!hideSeparator && (
			<Box
				sx={{
					height: "1px",
					backgroundColor: "#e0e0e0",
					mx: "4px",
					mb: "40px",
				}}
			/>
		)}
		{children}
	</Box>
);
