import React from "react";
import { TYPES_CUSTOMER_BEHAVIOR_STATISTIC } from "../constants";
import { MenuItem, Select } from "@mui/material";

export const SelectStatisticType = ({
	selectedType,
	setSelectedType,
	name,
}) => {
	return (
		<Select
			data-testid="select-statistic-type"
			value={selectedType}
			onChange={(e) => setSelectedType(e.target.value)}
			sx={{
				"height": 30,
				"backgroundColor": "white.main",
				".MuiOutlinedInput-notchedOutline": { border: 0 },
			}}
		>
			{TYPES_CUSTOMER_BEHAVIOR_STATISTIC.map((typeStatistic) => (
				<MenuItem
					key={`${typeStatistic.name}-${name}`}
					value={typeStatistic.key}
				>
					{typeStatistic.name}
				</MenuItem>
			))}
		</Select>
	);
};
