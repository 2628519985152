import React from "react";
import { Box, ButtonBase } from "@mui/material";
import { Icon } from "./Icon";
import { colors } from "../styles/colors";

export const AddOfferButton = ({ offersLength, onClick }) => (
	<Box
		sx={{
			border: "1px solid #EFF0F6",
			boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
			borderRadius: "20px",
			backgroundColor: colors.white,
			height: offersLength ? "100%" : "127px",
		}}
	>
		<ButtonBase
			sx={{
				width: "100%",
				height: "100%",
				borderRadius: "20px",
			}}
			data-testid="create-offer-button"
			onClick={onClick}
		>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				sx={{
					width: "48px",
					height: "48px",
					borderRadius: "50%",
					backgroundColor: "gray.dark",
					boxShadow: "0 10px 20px 0 rgba(17, 110, 95, 0.05)",
				}}
			>
				<Icon name="plusOffer" sx={{ fontSize: 16 }} />
			</Box>
		</ButtonBase>
	</Box>
);
