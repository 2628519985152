import React, { useState } from "react";
import { ModalContainer } from "./ModalContainer";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { deleteOffer } from "../store/offersSlice";
import { useDispatch, useSelector } from "react-redux";
import { showSnackBar } from "../store/uiSlice";
import { colors } from "../styles/colors";
import { LoadingButton } from "@mui/lab";

export const DeleteOffer = () => {
	const [loading, setLoading] = useState(false);
	const offer = useSelector(({ offers }) => offers.offerDetails);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	};

	const handleClose = () => {
		navigate("/offers");
	};

	const handleDeleteOffer = () => {
		setLoading(true);
		const onFinish = () => setLoading(false);
		const onSuccess = () => {
			dispatch(
				showSnackBar({
					text: `Offer ${offer.name} was deleted successfully`,
					severity: "success",
					duration: 3000,
				}),
			);
			handleClose();
		};

		dispatch(deleteOffer({ offerId: offer.id, onFinish, onSuccess }));
	};

	return (
		<ModalContainer
			headerStyle={{ mb: "20px" }}
			handleClose={handleBack}
			title={"Delete Offer"}
			centerTitle
		>
			<Typography
				sx={{
					fontSize: 16,
					fontWeight: "500",
					lineHeight: "19px",
					mb: "8px",
					textAlign: "center",
				}}
			>
				Are you sure you want to delete this Offer?
			</Typography>
			<Typography
				sx={{
					fontSize: 14,
					fontWeight: "400",
					lineHeight: "20px",
					mb: "20px",
					textAlign: "center",
				}}
			>
				This will delete all history of this offer, <br />
				this action cannot be undone.
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					px: "27px",
				}}
			>
				<Button
					sx={{
						"height": "40px",
						"borderRadius": "10px",
						"textTransform": "none",
						"fontSize": 16,
						"fontWeight": "400",
						"boxShadow": 0,
						"backgroundColor": colors.darkDarkGray,
						"width": "100%",
						"color": colors.white,
						"&:hover": {
							backgroundColor: colors.lightBlack,
						},
					}}
					color="inherit"
					variant="contained"
					onClick={handleBack}
				>
					Cancel, keep Offer
				</Button>
				<Box sx={{ width: "12px" }} />
				<LoadingButton
					sx={{
						height: "40px",
						borderRadius: "10px",
						textTransform: "none",
						fontSize: 16,
						fontWeight: "400",
						boxShadow: 0,
						width: "100%",
					}}
					color="error"
					variant="contained"
					onClick={handleDeleteOffer}
					loading={loading}
				>
					Yes, delete Offer
				</LoadingButton>
			</Box>
		</ModalContainer>
	);
};
