export const colors = {
	primary: "#116E5F",
	darkPrimary: "#61AEA0",
	lightPrimary: "#9ACDC1",
	lightLightPrimary: "#EEF6F4",
	veryLightPrimary: "#F7FBFA",
	black: "#000000",
	darkBlack: "#2c2c2c",
	lightBlack: "#4D4D4D",
	white: "#FFFFFF",
	gray: "#efeff2",
	darkGray: "#e3e9ec",
	darkDarkGray: "#8FA0B4",
	lightGray: "#f4f5f7",
	lightLightGray: "#F9F9F9",
	red: "#EF5457",
	transparent: "transparent",
};

export const chartColors = {
	green: "#9ACDC1",
	lightGreen: "#EEF6F4",
	darkGreen: "#61AEA0",
	blue: "#7CCEDF",
	lightBlue: "#ebf8fa",
	purple: "#938BDB",
	darkPurple: "#A82CD0",
	lightPurple: "#F2F1FC",
	red: "#D4767F",
	lightRed: "#FA94A6",
	yellow: "#FFDE8A",
	lightYellow: "#FFE6A6",
	lightLightYellow: "#fffaed",
	gray: "#E5E5E5",
};

export const offerColors = {
	paused: {
		primaryText: "#FFB293",
		progressBar: "#FFB293",
		statusText: "#FFB293",
		statusBackground: "#FFF3EF",
	},
	active: {
		primaryText: "#116E5F",
		progressBar: "#61AEA0",
		statusText: "#61AEA0",
		statusBackground: "#EEF6F4",
	},
	expired: {
		primaryText: "#EF5457",
		progressBar: "#EF5457",
		statusText: "#FA94A6",
		statusBackground: "#FDE5E7",
	},
	notStarted: {
		primaryText: "#8FA0B4",
		statusText: colors.black,
		statusBackground: colors.gray,
	},
};
