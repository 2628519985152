import React from "react";
import { Box, Typography } from "@mui/material";

export const PerformanceBlock = ({ name, colorTitle, value, type }) => (
	<Box
		sx={{
			boxShadow: 2,
			borderRadius: 4,
			px: 3,
			py: 2,
			backgroundColor: "white.main",
		}}
	>
		<Typography sx={{ fontWeight: 500, color: colorTitle }}>{name}</Typography>
		<Typography sx={{ fontSize: 24, fontWeight: 700 }}>
			{value.toLocaleString()}
			{type ? (
				<Box sx={{ fontSize: 16, fontWeight: 500 }} component="span">
					{` ${type}`}
				</Box>
			) : null}
		</Typography>
	</Box>
);
