import React from "react";
import { Box, Modal, Paper, Typography } from "@mui/material";
import { CloseButton } from "../components/CloseButton";

const MODAL_WIDTH = 500;

export const ModalContainer = ({
	title,
	centerTitle = false,
	handleClose,
	children,
	headerStyle,
	containerStyle,
}) => (
	<Modal
		sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
		open={true}
		onClose={handleClose}
	>
		<Paper
			sx={{
				width: MODAL_WIDTH,
				boxShadow: 0,
				overflow: "auto",
				maxHeight: "90vh",
				backgroundColor: "white.main",
				borderRadius: "10px",
				p: "40px",
				outline: "none",
				...containerStyle,
			}}
		>
			{centerTitle ? (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						mb: "24px",
						position: "relative",
						...headerStyle,
						height: "41px",
					}}
				>
					<Typography
						sx={{ fontWeight: "500", fontSize: 20, lineHeight: "24px" }}
					>
						{title}
					</Typography>
					<CloseButton
						containerStyle={{ position: "absolute", right: 0 }}
						handleClose={handleClose}
					/>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: "24px",
						...headerStyle,
					}}
				>
					<Typography
						sx={{ fontWeight: "500", fontSize: 20, lineHeight: "24px" }}
					>
						{title}
					</Typography>
					<CloseButton handleClose={handleClose} />
				</Box>
			)}
			{children}
		</Paper>
	</Modal>
);
