import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setAccessToken, setBrandCreated } from "store/authSlice";
import { Icon } from "./Icon";
import { SIGN_UP_SUBSCRIBE_ITEMS } from "../constants";
import { resetCreateBrandPdf } from "store/brandsSlice";

export default function SignUpSubscribe({ accessToken }) {
	const dispatch = useDispatch();

	const handleContinue = () => {
		localStorage.setItem("accessToken", accessToken);
		dispatch(setAccessToken(accessToken));
		dispatch(setBrandCreated(false));
		dispatch(resetCreateBrandPdf());
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontWeight: "700",
					fontSize: "40px",
					lineHeight: "44px",
					color: "#232323",
					letterSpacing: "-0.04em",
					mb: "12px",
				}}
			>
				Subscribe
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#969696",
					mb: "32px",
				}}
			>
				get access to advance features and unlock more with Mthmr
			</Typography>
			<Box
				sx={{
					borderRadius: "20px",
					padding: "20px 24px",
					backgroundColor: "#EEF6F4",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontWeight: "500",
						fontSize: "24px",
						lineHeight: "27px",
						letterSpacing: "-0.04em",
						color: "primary.main",
						mb: "24px",
						textAlign: "center",
					}}
				>
					Enjoy 1-month free trail
				</Typography>
				{SIGN_UP_SUBSCRIBE_ITEMS.map((subscribe_item) => (
					<Box
						key={subscribe_item}
						sx={{
							display: "flex",
							alignItems: "center",
							mb: "24px",
						}}
					>
						<Box
							sx={{
								backgroundColor: "primary.main",
								width: "16px",
								height: "16px",
								borderRadius: "50%",
								marginRight: "24px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexShrink: 0,
							}}
						>
							<Icon
								name="checkboxBold"
								style={{ width: "5.2px", height: "4px" }}
							/>
						</Box>
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "16px",
								lineHeight: "20px",
								color: "#2C2C2C",
								width: "100%",
							}}
						>
							{subscribe_item}
						</Typography>
					</Box>
				))}
			</Box>
			<Button
				variant="contained"
				color="primary"
				fullWidth
				onClick={handleContinue}
				sx={{
					height: "54px",
					borderRadius: "10px",
					mt: "32px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontWeight: "600",
						fontSize: "18px",
						lineHeight: "22px",
						letterSpacing: "-0.01em",
						textTransform: "none",
					}}
				>
					Let&apos;s get started
				</Typography>
			</Button>
		</Box>
	);
}
