export const sortByField = (field) => {
	return (a, b) => {
		if (typeof a[field] === "string" && typeof b[field] === "string") {
			return a[field].localeCompare(b[field]);
		} else if (typeof a[field] === "number" && typeof b[field] === "number") {
			return b[field] - a[field]; // Compare in reverse order for numbers
		} else {
			// Handle cases when the types are different
			return 0;
		}
	};
};
