import { Box } from "@mui/material";
import React from "react";
import { Icon } from "./Icon";

export const CloseButton = ({ containerStyle, handleClose }) => {
	return (
		<Box
			sx={{
				"width": "40px",
				"height": "40px",
				"borderRadius": "50%",
				"backgroundColor": "primary.lightLight",
				"display": "flex",
				"justifyContent": "center",
				"alignItems": "center",
				"&:hover": {
					cursor: "pointer",
				},
				...containerStyle,
			}}
			data-testid="close-button"
			onClick={handleClose}
		>
			<Icon name="closePrimary" />
		</Box>
	);
};
