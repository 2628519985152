import React from "react";
import { Box } from "@mui/material";

export const RadioButtonCircle = ({ selected }) => (
	<Box
		sx={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "15px",
			height: "15px",
			borderRadius: "100px",
			border: "1px solid #818094",
		}}
	>
		{selected && (
			<Box
				sx={{
					width: "9px",
					height: "9px",
					backgroundColor: "primary.main",
					borderRadius: "100px",
				}}
			/>
		)}
	</Box>
);
