import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Backdrop, Box, ButtonBase, Typography } from "@mui/material";
import { Icon } from "./Icon";
import { colors } from "../styles/colors";
import { Checkbox } from "./Checkbox";

export const MultiSelect = ({
	name,
	data,
	selectedItems,
	setSelectedItems,
	allOption,
	placeholder,
	error,
	helperText,
	label,
	containerStyle,
}) => {
	const dropdownRef = useRef();
	const containerRef = useRef();

	const [isOpen, setIsOpen] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [heightDropdown, setHeightDropdown] = useState(0);

	const updatePosition = useCallback(() => {
		const rect = containerRef.current.getBoundingClientRect();

		const yOffset =
			window.innerHeight - rect.top < heightDropdown + 50
				? rect.top - heightDropdown - 4
				: rect.bottom + 4;

		setPosition({
			x: rect.left,
			y: yOffset,
		});
	}, [heightDropdown]);

	useEffect(() => {
		window.addEventListener("scroll", updatePosition, true);
		window.addEventListener("resize", updatePosition);

		updatePosition();

		return () => {
			window.removeEventListener("scroll", updatePosition, true);
			window.removeEventListener("resize", updatePosition);
		};
	}, [updatePosition]);

	useEffect(() => {
		if (dropdownRef.current) {
			setHeightDropdown(dropdownRef.current.clientHeight);
		}
	}, []);

	const allValue = useMemo(() => ({ id: `${name}-all`, name: "All" }), [name]);

	const currentValues = useMemo(() => {
		if (allOption) {
			return [allValue, ...data];
		}

		return data;
	}, [data, allOption, allValue]);

	const allSelected = useMemo(() => {
		if (!allOption) {
			return false;
		}

		const everySelected = data.every((dataItem) =>
			selectedItems.some((selectedItem) => selectedItem.id === dataItem.id),
		);

		return everySelected;
	}, [data, selectedItems, allOption]);

	const onChange = useCallback(
		(newItem) => {
			if (newItem.id === allValue.id) {
				// handle all option pressed
				if (allSelected) {
					setSelectedItems([]);
				} else {
					setSelectedItems(data);
				}

				return;
			}

			const isSelected = selectedItems.some(
				(selectedItem) => selectedItem.id === newItem.id,
			);

			let newSelectedItems = [];

			if (isSelected) {
				newSelectedItems = selectedItems.filter(
					(selectedItem) => selectedItem.id !== newItem.id,
				);
			} else {
				newSelectedItems = [...selectedItems, newItem];
			}

			setSelectedItems(newSelectedItems);
		},
		[currentValues, selectedItems, allSelected],
	);

	const renderName = useMemo(() => {
		if (!selectedItems.length) {
			return placeholder;
		}

		if (allSelected) {
			return "All";
		}

		return selectedItems.map((selectedItem, i) => {
			if (i === selectedItems.length - 1) {
				return selectedItem.name;
			}

			return `${selectedItem.name}, `;
		});
	}, [selectedItems, allSelected]);

	return (
		<Box sx={containerStyle}>
			<Typography
				sx={{
					fontSize: 16,
					lineHeight: "19px",
					mb: "8px",
					color: "#030229",
				}}
			>
				{label}
			</Typography>
			<Box
				ref={containerRef}
				sx={{
					"border": "1px solid #8FA0B4",
					"borderRadius": "10px",
					"height": "50px",
					"display": "flex",
					"justifyContent": "space-between",
					"alignItems": "center",
					"px": "20px",
					"&:hover": {
						cursor: "pointer",
					},
				}}
				onClick={() => setIsOpen(true)}
			>
				<Typography
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						WebkitBoxOrient: "vertical",
						whiteSpace: "normal",
						WebkitLineClamp: 1,
						wordWrap: "break-word",
					}}
					sx={{ fontSize: 12, color: "primary.main" }}
				>
					{renderName}
				</Typography>
				<Icon name="arrowDownMultiSelect" />
			</Box>
			{error ? (
				<Typography
					sx={{
						fontSize: 12,
						lineHeight: "14px",
						mt: "2px",
						color: "error.main",
						ml: "10px",
					}}
				>
					{helperText}
				</Typography>
			) : (
				<Box sx={{ height: "16px" }} />
			)}
			<Backdrop
				sx={{
					color: colors.white,
					backgroundColor: colors.transparent,
					zIndex: 1,
				}}
				open={isOpen}
				onClick={() => setIsOpen(false)}
			>
				<Box
					ref={dropdownRef}
					sx={{
						backgroundColor: "white.main",
						borderRadius: "20px",
						boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.1)",
						width: 263,
						py: "12px",
						position: "absolute",
						top: position.y,
						left: position.x,
						display: "flex",
						flexDirection: "column",
					}}
					onClick={(e) => e.stopPropagation()}
				>
					{currentValues.map((value) => {
						if (value.id === allValue.id) {
							return (
								<ButtonBase
									key={value.id}
									sx={{
										px: "21px",
										height: "31px",
										justifyContent: "flex-start",
										backgroundColor: allSelected ? "#e9ecf0" : "white.main",
									}}
									onClick={() => onChange(value)}
								>
									<Typography sx={{ fontSize: 12, color: "black.dark" }}>
										{value.name}
									</Typography>
								</ButtonBase>
							);
						}

						const checked = selectedItems.some(
							(selectedItem) => selectedItem.id === value.id,
						);

						return (
							<Checkbox
								key={value.id}
								checked={checked}
								onChange={() => onChange(value)}
								name={value.name}
								containerStyle={{
									height: "31px",
									px: "21px",
									backgroundColor: checked ? "#e9ecf0" : "white.main",
								}}
							/>
						);
					})}
				</Box>
			</Backdrop>
		</Box>
	);
};
