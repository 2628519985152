import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";
import { colors as appColors } from "../styles/colors";
import "react-circular-progressbar/dist/styles.css";

export const PieChart = ({
	containerStyle,
	value,
	percentage,
	units,
	name,
	colors,
}) => {
	const { activeColor, inactiveColor } = useMemo(() => {
		if (colors?.length < 2) {
			return {
				activeColor: appColors.lightPrimary,
				inactiveColor: appColors.lightLightPrimary,
			};
		}

		return { activeColor: colors[0], inactiveColor: colors[1] };
	}, [colors]);

	return (
		<Box
			data-testid="pie-chart"
			sx={{ position: "relative", ...containerStyle }}
		>
			<Box sx={{ position: "relative" }}>
				<CircularProgressbar
					value={percentage}
					styles={{
						path: {
							stroke: activeColor,
						},
						trail: {
							stroke: inactiveColor,
						},
						text: {
							fontFamily: "Inter",
							fontSize: 16,
							fill: appColors.darkBlack,
							fontWeight: "700",
						},
					}}
					strokeWidth={12}
					text={`${value}${units ?? ""}`}
				/>
			</Box>
			<Typography
				sx={{
					mt: "24px",
					fontWeight: "500",
					fontSize: 16,
					lineHeight: "19px",
					textAlign: "center",
				}}
			>
				{name}
			</Typography>
		</Box>
	);
};
