import React, { useState } from "react";
import Box from "@mui/material/Box";
import VerifyEmail from "./VerifyEmail";
import SignUp from "./SignUp";
import { CreateFirstBrand } from "./CreateFirstBrand";
import SignUpSubscribe from "./SignUpSubscribe";
import { SignUpProgressTracker } from "./SignUpProgressTracker";

export const Registration = ({ switchToSignIn }) => {
	const [currentStep, setCurrentStep] = useState(0);
	const [verificationCode, setVerificationCode] = useState("");
	const [accessToken, setAccessToken] = useState("");

	const steps = {
		0: () => (
			<SignUp setCurrentStep={setCurrentStep} switchToSignIn={switchToSignIn} />
		),
		1: () => <CreateFirstBrand setCurrentStep={setCurrentStep} />,
		2: () => (
			<VerifyEmail
				setAccessToken={setAccessToken}
				verificationCode={verificationCode}
				setVerificationCode={setVerificationCode}
				setCurrentStep={setCurrentStep}
			/>
		),
		3: () => <SignUpSubscribe accessToken={accessToken} />,
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				flex: 1,
				px: "64px",
			}}
		>
			<SignUpProgressTracker
				currentStep={currentStep}
				containerStyle={{
					marginBottom: "24px",
					width: "102%",
					position: "relative",
					left: "-1%", // setting sign up progress tracked a bit bigger than the parent container
				}}
			/>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					alignItems: "center",
				}}
			>
				{steps[currentStep]()}
			</Box>
		</Box>
	);
};
