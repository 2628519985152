import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveLine } from "@nivo/line";
import {
	fetchPerformance,
	fetchPerformanceCSV,
} from "../store/performanceSlice";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { NoData } from "../components/NoData";
import { PerformanceBlock } from "../components/PerformanceBlock";
import { chartColors } from "../styles/colors";

const LineChart = ({ data }) => {
	return (
		<ResponsiveLine
			data={data}
			colors={[
				chartColors.darkGreen,
				chartColors.red,
				chartColors.yellow,
				chartColors.darkPurple,
			]}
			margin={{ top: 50, bottom: 35, left: 35, right: 35 }}
			xScale={{ type: "point" }}
			enablePoints={false}
			curve="monotoneX"
			yFormat=" >-.2f"
			axisTop={null}
			axisLeft={null}
			axisRight={null}
			enableGridX={false}
			enableGridY={false}
			isInteractive={false}
			lineWidth={6}
			legends={[
				{
					anchor: "top-left",
					justify: false,
					direction: "row",
					translateX: -30,
					translateY: -25,
					itemWidth: 120,
					itemsSpacing: 20,
					symbolSize: 12,
					symbolShape: "circle",
				},
			]}
		/>
	);
};

const LoaderChart = () => (
	<>
		<Box
			sx={{
				width: "100%",
				height: "100%",
				position: "absolute",
				borderRadius: 5,
				backgroundColor: "white.main",
				zIndex: 1,
				opacity: 0.7,
			}}
		/>
		<CircularProgress sx={{ position: "absolute" }} />
	</>
);

export const Performance = () => {
	const dispatch = useDispatch();
	const performance = useSelector((state) => state.performance.performance);
	const offers = useSelector((state) => state.offers.offers);
	const [selectedOffer, setSelectedOffer] = useState(null);
	const [isLoadingPerformance, setIsLoadingPerformance] = useState(false);
	const [isLoadingDownloadCSV, setIsLoadingDownloadCSV] = useState(false);

	useEffect(() => {
		if (offers?.length && !selectedOffer) {
			setSelectedOffer(offers[0]);
		}
	}, [offers, selectedOffer]);

	useEffect(() => {
		if (selectedOffer?.id) {
			setIsLoadingPerformance(true);
			dispatch(
				fetchPerformance({
					offerId: selectedOffer.id,
					onLoadingEnd: () => setIsLoadingPerformance(false),
				}),
			);
		}
	}, [dispatch, selectedOffer]);

	const isPerformanceExists = useMemo(
		() => !!Object.keys(performance).length,
		[performance],
	);

	const handleChangeOffer = (e) => {
		const newSelectedOfferId = e.target.value;
		setSelectedOffer(offers.find((offer) => offer.id === newSelectedOfferId));
	};

	const handleDownloadCSV = () => {
		setIsLoadingDownloadCSV(true);
		dispatch(
			fetchPerformanceCSV({
				fileName: `${selectedOffer.name}_${dayjs().format(
					"YYYY-MM-DD_HH:mm:ss",
				)}`,
				offerId: selectedOffer.id,
				onLoadingEnd: () => setIsLoadingDownloadCSV(false),
			}),
		);
	};

	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				backgroundColor: "gray.lightLight",
				height: "100vh",
				overflow: "auto",
				px: 5,
				py: 3,
			}}
		>
			<Typography variant="h4" sx={{ fontWeight: "700", marginBottom: 4 }}>
				Performance
			</Typography>
			<Divider sx={{ marginBottom: 5 }} />
			{offers?.length && selectedOffer ? (
				<>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						display="flex"
					>
						<Grid item>
							<FormControl sx={{ width: 300 }}>
								<Select
									value={selectedOffer?.id}
									onChange={handleChangeOffer}
									sx={{
										borderRadius: 10,
										height: 40,
										px: 1,
										backgroundColor: "white.main",
										boxShadow: 1,
									}}
								>
									{offers.map((offer) => {
										const isSelectedOffer = offer.id === selectedOffer.id;

										return (
											<MenuItem key={offer.id} value={offer.id}>
												{isSelectedOffer ? `Offer: ${offer.name}` : offer.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						{isPerformanceExists ? (
							<Grid item>
								<LoadingButton
									onClick={handleDownloadCSV}
									loading={isLoadingDownloadCSV}
									sx={{ fontSize: 14, color: "black.light" }}
								>
									<DownloadIcon fontSize="small" sx={{ mr: "4px" }} />
									Download
								</LoadingButton>
							</Grid>
						) : null}
					</Grid>

					{isPerformanceExists ? (
						<>
							<Grid container columnGap={2} sx={{ mt: 3 }}>
								<Grid item sx={{ flexGrow: 1 }}>
									<PerformanceBlock
										name="No. of transactions"
										colorTitle={chartColors.darkGreen}
										value={performance.noTransactions}
									/>
								</Grid>
								<Grid item sx={{ flexGrow: 1 }}>
									<PerformanceBlock
										name="Sales"
										colorTitle={chartColors.purple}
										value={performance.Sales}
										type="SAR"
									/>
								</Grid>
								<Grid item sx={{ flexGrow: 1 }}>
									<PerformanceBlock
										name="AOV"
										colorTitle={chartColors.yellow}
										value={performance.AOV}
										type="SAR"
									/>
								</Grid>
								<Grid item sx={{ flexGrow: 1 }}>
									<PerformanceBlock
										name="Remaining Budget"
										colorTitle={chartColors.lightRed}
										value={performance.remainingBudget}
										type="SAR"
									/>
								</Grid>
							</Grid>
							<Box
								sx={{
									height: "30vh",
									backgroundColor: "white.main",
									boxShadow: 1,
									borderRadius: 5,
									px: 3,
									mt: 4,
									position: "relative",
								}}
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								{isLoadingPerformance ? <LoaderChart /> : null}
								<LineChart data={performance.chartData} />
							</Box>
						</>
					) : (
						<NoData />
					)}
				</>
			) : (
				<NoData />
			)}
		</Box>
	);
};
