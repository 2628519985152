import { Box, Typography } from "@mui/material";
import React from "react";
import { HomeItemWrapper } from "./HomeItemWrapper";
import countryBackground from "../../assets/icons/countryBackground.svg";
import { chartColors, colors } from "../../styles/colors";
import { Icon } from "../Icon";
import { NoData } from "../NoData";

const HomeClientLocationItem = ({ name, iconName, value, color }) => (
	<Box
		sx={{
			p: "12px 16px",
			backgroundColor: colors.white,
			borderRadius: "13px",
			border: "1px solid #EFF0F6",
			boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
			width: "100%",
			position: "relative",
			height: "83px",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			minWidth: "142px",
		}}
	>
		<Box>
			<Typography
				sx={{
					fontSize: 12,
					lineHeight: "14px",
					fontWeight: "600",
					mb: "12px",
					color,
				}}
			>
				{name}
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: 24,
					lineHeight: "29px",
					fontWeight: "700",
					color,
				}}
			>
				{value}
				<Box
					sx={{
						fontSize: 16,
						lineHeight: "19px",
						fontWeight: "500",
					}}
					component="span"
				>
					{` %`}
				</Box>
			</Typography>
		</Box>
		<Icon name={iconName} />
	</Box>
);

export const HomeClientsLocation = ({ data, loading }) => {
	return (
		<HomeItemWrapper
			title={"Clients Location"}
			loading={loading}
			containerStyle={{
				backgroundImage: `url(${countryBackground})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			{data ? (
				<>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							mb: "20px",
						}}
					>
						<HomeClientLocationItem
							name="Riyadh"
							value={data.riyadh}
							iconName={"riyadh"}
							color={chartColors.darkGreen}
						/>
						<Box sx={{ width: "30px" }} />
						<HomeClientLocationItem
							name="Jeddah"
							value={data.jeddah}
							iconName={"jeddah"}
							color={chartColors.purple}
						/>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<HomeClientLocationItem
							name="Eastern Prov"
							value={data.easternProv}
							iconName={"easternProv"}
							color={chartColors.blue}
						/>
						<Box sx={{ width: "30px" }} />
						<HomeClientLocationItem
							name="Other"
							value={data.other}
							iconName={"saudiArabia"}
							color={chartColors.lightRed}
						/>
					</Box>
				</>
			) : (
				<NoData />
			)}
		</HomeItemWrapper>
	);
};
