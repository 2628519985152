import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const OrDivider = ({ containerStyle }) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				...containerStyle,
			}}
		>
			<Box sx={{ width: "100%", height: "1px", backgroundColor: "#D9D9D9" }} />
			<Typography
				sx={{
					fontFamily: "Inter",
					fontWeight: "500",
					fontSize: "16px",
					lineHeight: "24px",
					color: "#6E6E6E",
					mx: "10px",
				}}
			>
				or
			</Typography>
			<Box sx={{ width: "100%", height: "1px", backgroundColor: "#D9D9D9" }} />
		</Box>
	);
};
