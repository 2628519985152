import React, { useEffect, useState } from "react";
import { Box, Button, ButtonBase, Typography } from "@mui/material";
import { setNewOffer } from "../store/offersSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelectWithSearch } from "../components/MultiSelectWithSearch";
import { ModalContainer } from "./ModalContainer";
import { SelectedItem } from "../components/SelectedItem";

export const CreateOfferLocation = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const catalogs = useSelector(({ offers }) => offers.catalogs);
	const newOffer = useSelector(({ offers }) => offers.newOffer);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [error, setError] = useState("");

	const handleClose = () => {
		navigate("/offers");
		handleReset();
	};

	const handleReset = () => {
		setSelectedLocations([]);
		setError("");
	};

	useEffect(() => {
		if (!Object.keys(newOffer).length) {
			setSelectedLocations([]);
		} else if ("location" in newOffer) {
			setSelectedLocations(newOffer.location);
		}
	}, [newOffer]);

	const handleContinue = () => {
		if (selectedLocations.length) {
			dispatch(setNewOffer({ ...newOffer, location: selectedLocations }));
			navigate("/offers/create-offer-client-of");
		} else {
			setError("Location is required");
		}
	};

	const onChangeSelectedItems = (items) => {
		if (error) {
			setError("");
		}
		setSelectedLocations(items);
	};

	if (!catalogs?.locations) {
		return null;
	}

	return (
		<ModalContainer
			title={"Create New Offer"}
			handleClose={handleClose}
			containerStyle={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flex: 1,
				}}
			>
				<Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: "16px",
						}}
					>
						<Typography
							sx={{ fontSize: 16, lineHeight: "19px", color: "#030229" }}
						>
							Location
						</Typography>
						<ButtonBase disableRipple onClick={handleReset}>
							<Typography
								sx={{
									fontSize: 12,
									fontWeight: "600",
									lineHeight: "14px",
									color: "primary.main",
									textDecorationLine: "underline",
								}}
							>
								Reset
							</Typography>
						</ButtonBase>
					</Box>
					{selectedLocations.length ? (
						<Box display="flex" flexWrap="wrap" gap={"5px"} sx={{ mb: "16px" }}>
							{selectedLocations.map((selectedLocation) => (
								<SelectedItem
									key={selectedLocation.id}
									name={selectedLocation.name}
									id={selectedLocation.id}
									onRemove={(id) =>
										setSelectedLocations((prev) =>
											prev.filter((location) => location.id !== id),
										)
									}
								/>
							))}
						</Box>
					) : null}
					<MultiSelectWithSearch
						data={catalogs.locations}
						selectedItems={selectedLocations}
						setSelectedItems={onChangeSelectedItems}
					/>
					{error && (
						<Typography
							sx={{
								fontSize: 12,
								lineHeight: "14px",
								mt: "2px",
								color: "error.main",
								ml: "10px",
							}}
						>
							{error}
						</Typography>
					)}
				</Box>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						sx={{
							height: "45px",
							mt: "8px",
							borderRadius: "10px",
							width: "238px",
						}}
						variant="contained"
						onClick={handleContinue}
					>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "600",
								fontSize: 16,
								textTransform: "capitalize",
							}}
						>
							Continue
						</Typography>
					</Button>
				</Box>
			</Box>
		</ModalContainer>
	);
};
