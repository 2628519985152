import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomerBehaviorLineChart } from "../components/CustomerBehaviorLineChart";
import { CustomBarChart } from "../components/CustomBarChart";
import { fetchCustomerBehavior } from "../store/customerBehaviorSlice";
import { NoData } from "../components/NoData";
import DateRangePicker from "../components/DateRangePicker";
import dayjs from "dayjs";
import { TYPES_CUSTOMER_BEHAVIOR_STATISTIC } from "../constants";
import { Icon } from "../components/Icon";
import { SkeletonCustomerBehavior } from "../components/skeletons/SkeletonCustomerBehavior";
import { chartColors, colors } from "../styles/colors";
import { Screen } from "../components/Screen";

const defaultType = TYPES_CUSTOMER_BEHAVIOR_STATISTIC[0].key;

export const CustomerBehavior = () => {
	const dispatch = useDispatch();
	const [selectedAgeType, setSelectedAgeType] = useState(defaultType);
	const [selectedGenderType, setSelectedGenderType] = useState(defaultType);
	const [loading, setLoading] = useState(true);
	const selectedBrand = useSelector(({ brands }) => brands.selectedBrand);

	const customerBehavior = useSelector(
		({ customerBehavior }) => customerBehavior.customerBehavior,
	);

	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});

	useEffect(() => {
		if (!selectedBrand) {
			return;
		}

		setLoading(true);
		const date_from = dayjs(dateRange.startDate).format("YYYY-MM-DD");
		const date_to = dayjs(dateRange.endDate).format("YYYY-MM-DD");
		dispatch(
			fetchCustomerBehavior({
				date_from,
				date_to,
				onFinish: () => setLoading(false),
			}),
		);
	}, [dateRange, dispatch, selectedBrand]);

	return (
		<Screen title={"Customer Persona & Behavior"}>
			<DateRangePicker
				value={dateRange}
				onChange={setDateRange}
				containerStyle={{ mb: "24px" }}
			/>
			{loading ? (
				<SkeletonCustomerBehavior />
			) : customerBehavior ? (
				<>
					<Grid container gap={3} sx={{ mb: 3 }}>
						<Grid item xs>
							<CustomerBehaviorLineChart
								data={customerBehavior.spend}
								color={chartColors.green}
								name="Spend"
								units={"SAR"}
								icon={<Icon name="dollar" style={{ fontSize: 20 }} />}
								iconBackgroundColor={colors.lightLightPrimary}
							/>
						</Grid>
						<Grid item xs>
							<CustomerBehaviorLineChart
								data={customerBehavior.noTransactions}
								color={chartColors.purple}
								name="No. of transactions"
								icon={<Icon name="list" style={{ fontSize: 20 }} />}
								iconBackgroundColor={chartColors.lightPurple}
							/>
						</Grid>
						<Grid item xs>
							<CustomerBehaviorLineChart
								data={customerBehavior.AOV}
								color={chartColors.yellow}
								name="AOV"
								units={"SAR"}
								icon={<Icon name="shopping" style={{ fontSize: 20 }} />}
								iconBackgroundColor={chartColors.lightLightYellow}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3} sx={{ mb: 3 }}>
						<Grid item xs={8}>
							<CustomBarChart
								name="Age"
								selectedType={selectedAgeType}
								setSelectedType={setSelectedAgeType}
								chart={{
									data: customerBehavior.age[selectedAgeType],
									layout: "horizontal",
									indexBy: "label",
									axisBottom: null,
									axisLeft: {},
									colors: [
										chartColors.darkGreen,
										chartColors.purple,
										chartColors.blue,
										chartColors.yellow,
									],
									margin: { left: 50 },
									units: selectedAgeType === "noTransactions" ? "" : "SAR",
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<CustomBarChart
								name="Gender"
								selectedType={selectedGenderType}
								setSelectedType={setSelectedGenderType}
								chart={{
									data: customerBehavior.gender[selectedGenderType],
									layout: "vertical",
									indexBy: "label",
									axisLeft: null,
									axisBottom: {},
									maxValue: "auto",
									colors: [chartColors.purple, chartColors.darkGreen],
									units: selectedGenderType === "noTransactions" ? "" : "SAR",
								}}
							/>
						</Grid>
					</Grid>
					{/* TODO: we will release it in the next version
            <TypeOfClientPieCharts
              data={customerBehavior.typeOfClients}
              selectedType={selectedClientsType}
              setSelectedType={setSelectedClientsType}
            /> */}
				</>
			) : (
				<NoData />
			)}
		</Screen>
	);
};
