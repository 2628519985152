import React from "react";
import { Skeleton } from "@mui/material";

export const SkeletonOffer = () => (
	<Skeleton
		variant="rounded"
		animation="wave"
		height={"127px"}
		width={"100%"}
		sx={{ borderRadius: "20px" }}
	/>
);
