import React, { useState } from "react";
import DateRangePicker from "./DateRangePicker/DateRangePicker";
import { Backdrop, Box, ButtonBase, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./styles.scss";
import "./theme.scss";
import { colors } from "../../styles/colors";

const DateRangePickerContainer = ({ value, onChange, containerStyle }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDefinedRange, setSelectedDefinedRange] = useState(2);

	const handleChangeDateRange = (dateRange) => {
		const { startDate, endDate } = dateRange.selection;
		onChange({ startDate, endDate });
	};

	return (
		<Box
			sx={{ position: "relative", ...containerStyle }}
			data-testid="date-range-picker"
		>
			<ButtonBase
				sx={{
					borderRadius: 30,
					height: 40,
					backgroundColor: "white.main",
					boxShadow: 1,
					width: 300,
					pr: 1,
					pl: 2,
				}}
				data-testid="date-range-picker-open-button"
				style={{ display: "flex", justifyContent: "space-between" }}
				onClick={() => setIsOpen((prev) => !prev)}
			>
				<Typography>{`Timeframe: ${selectedDefinedRange.label}`}</Typography>
				<ArrowDropDownIcon fontSize="30" sx={{ width: 20, height: 20 }} />
			</ButtonBase>
			<Backdrop
				sx={{
					color: colors.white,
					zIndex: 10,
					backgroundColor: colors.transparent,
				}}
				open={isOpen}
				onClick={() => setIsOpen(false)}
			>
				<Box
					sx={{
						backgroundColor: "white.main",
						borderRadius: 5,
						p: 3,
						boxShadow: 2,
						width: 500,
						position: "absolute",
						top: 190,
						left: 250,
						mx: 3,
					}}
					onClick={(e) => e.stopPropagation()}
				>
					<DateRangePicker
						selectedDefinedRange={selectedDefinedRange}
						setSelectedDefinedRange={setSelectedDefinedRange}
						showDateDisplay={false}
						ranges={[{ ...value, key: "selection" }]}
						onChange={handleChangeDateRange}
					/>
				</Box>
			</Backdrop>
		</Box>
	);
};

export default DateRangePickerContainer;
