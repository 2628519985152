import React, { useState } from "react";
import { Box, Button, Checkbox, Popover, Typography } from "@mui/material";
import {
	FILTERS_COMPETITION,
	FILTER_COMPETITION_DEFAULT_SELECTED_VALUES,
} from "../constants";
import { Icon } from "./Icon";

const CheckboxItem = ({ name, value, onChange }) => (
	<Box display="flex" alignItems="center">
		<Checkbox
			sx={{ py: "6px", px: 0, m: 0, mr: "10px" }}
			checked={value}
			onChange={(e) => {
				onChange(e.target.checked);
			}}
			data-testid={`checkbox-${name}`}
		/>
		<Typography sx={{ fontSize: 12, color: "black.main" }}>{name}</Typography>
	</Box>
);

const id = "filter-pop-up";

export const FilterCompetition = ({ handleDone }) => {
	const [selectedValues, setSelectedValues] = useState(
		FILTER_COMPETITION_DEFAULT_SELECTED_VALUES,
	);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeSelectedValues = (newValue, name, type) => {
		setSelectedValues((prev) => ({
			...prev,
			[type]: { ...prev[type], [name]: newValue },
		}));
	};

	const handleReset = () => {
		setSelectedValues(FILTER_COMPETITION_DEFAULT_SELECTED_VALUES);
	};

	const handleDoneButton = () => {
		handleClose();

		const params = {};
		Object.keys(selectedValues).forEach((keyType) => {
			const typeObj = selectedValues[keyType];
			let currentParam = [];
			Object.keys(typeObj).forEach((keyName) => {
				if (typeObj[keyName]) {
					currentParam.push(keyName);
				}
			});

			if (currentParam.length) {
				params[keyType] = currentParam;
			}
		});

		handleDone(params);
	};

	return (
		<>
			<Box
				sx={{
					"display": "flex",
					"alignItems": "center",
					"&:hover": {
						cursor: "pointer",
					},
				}}
				onClick={handleOpen}
				aria-describedby={id}
			>
				<Typography
					sx={{
						fontSize: 12,
						lineHeight: "14px",
						color: "primary.main",
						mr: "4px",
					}}
				>
					Filters
				</Typography>
				<Icon name="filter" />
			</Box>
			<Popover
				id={id}
				open={!!anchorEl}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: 6,
					horizontal: 240,
				}}
				sx={{
					"& .MuiPaper-root": {
						borderRadius: "20px",
						width: "260px",
						backgroundColor: "white.main",
						px: "16px",
						pt: "24px",
						pb: "16px",
						mr: "100px",
						boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.1)",
					},
				}}
			>
				<Typography
					sx={{
						fontSize: 12,
						fontWeight: "600",
						mb: "4px",
						color: "black.main",
					}}
				>
					Gender
				</Typography>
				{FILTERS_COMPETITION.gender.map(({ name, id }) => (
					<CheckboxItem
						key={id}
						name={name}
						value={!!selectedValues.gender?.[name]}
						onChange={(newValue) =>
							handleChangeSelectedValues(newValue, name, "gender")
						}
					/>
				))}
				<Typography
					sx={{
						fontSize: 12,
						mt: "10px",
						mb: "4px",
						fontWeight: "600",
						color: "black.main",
					}}
				>
					Age
				</Typography>
				{FILTERS_COMPETITION.age.map(({ name, id }) => (
					<CheckboxItem
						key={id}
						name={name}
						value={!!selectedValues.age?.[name]}
						onChange={(newValue) =>
							handleChangeSelectedValues(newValue, name, "age")
						}
					/>
				))}
				{/* <Typography TODO: uncomment this when API is ready
					sx={{
						fontSize: 12,
						fontWeight: "600",
						mt: "10px",
						mb: "4px",
						color: "black.main",
					}}
				>
					Location
				</Typography>
				{FILTERS_COMPETITION.location.map(({ name, id }) => ( 
					<CheckboxItem
						key={id}
						name={name}
						value={!!selectedValues.location?.[name]}
						onChange={(newValue) =>
							handleChangeSelectedValues(newValue, name, "location")
						}
					/>
				))} */}
				<Box
					display="flex"
					sx={{
						mt: "10px",
					}}
					columnGap={"10px"}
				>
					<Button
						variant="outlined"
						sx={{ height: "29px", border: "1px solid #efefff" }}
						fullWidth
						onClick={handleReset}
					>
						<Typography
							sx={{
								fontSize: 12,
								fontWeight: "600",
								textTransform: "capitalize",
							}}
						>
							Reset
						</Typography>
					</Button>
					<Button
						variant="contained"
						sx={{
							height: "29px",
						}}
						fullWidth
						onClick={handleDoneButton}
					>
						<Typography
							sx={{
								fontSize: 12,
								fontWeight: "600",
								textTransform: "capitalize",
							}}
						>
							Done
						</Typography>
					</Button>
				</Box>
			</Popover>
		</>
	);
};
