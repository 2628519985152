import { Box, ButtonBase, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Icon } from "./Icon";

export const Checkbox = ({
	disableRipple,
	checked,
	onChange,
	name,
	containerStyle,
	gap = 10,
	textStyle,
}) => {
	const gapStyle = useMemo(() => ({ marginRight: `${gap}px` }), [gap]);

	return (
		<ButtonBase
			sx={{
				alignItems: "center",
				justifyContent: "flex-start",
				...containerStyle,
			}}
			disableRipple={disableRipple}
			onClick={onChange}
		>
			{checked ? (
				<Icon name={"checkbox"} style={gapStyle} />
			) : (
				<Box
					sx={{
						width: "15px",
						height: "15px",
						border: "1px solid #030229",
						borderRadius: "2px",
						...gapStyle,
					}}
				/>
			)}
			<Typography sx={{ fontSize: 12, color: "black.dark", ...textStyle }}>
				{name}
			</Typography>
		</ButtonBase>
	);
};
