import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Select as SelectMUI,
	Typography,
} from "@mui/material";
import { Icon } from "./Icon";

export const Select = ({
	isLoading,
	name,
	items,
	value,
	onChange,
	label,
	disabled = false,
	errors,
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	return (
		<Box
			sx={{
				mb: "24px",
			}}
		>
			<Box>
				{isLoading ? (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "59px",
						}}
					>
						<CircularProgress size={30} />
					</Box>
				) : (
					<Box
						sx={{
							width: "100%",
							position: "relative",
							boxSizing: "border-box",
							height: "59px",
						}}
					>
						<Typography
							sx={{
								position: "absolute",
								left: "12px",
								top: "16px",
								transition: "0.3s",
								fontFamily: "Inter",
								fontSize: "18px",
								transform:
									isFocused || value
										? "translateY(-27px) scale(0.777)"
										: "translateY(0) scale(1)",
								transformOrigin: "left top",
								fontWeight: "400",
								textShadow: isFocused
									? "0 0 0.5px #61AEA0"
									: value
										? "0 0 0.5px #9A9A9A"
										: "none",
								lineHeight: "27px",
								color: errors?.length
									? "#EF5457"
									: isFocused
										? "#61AEA0"
										: "#9A9A9A",
								backgroundColor: "white.main",
								zIndex: 2,
								pointerEvents: "none",
								padding: "0 4px",
							}}
						>
							{label}
						</Typography>
						<SelectMUI
							name={name}
							value={value}
							onChange={onChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							disabled={disabled}
							displayEmpty
							IconComponent={() => (
								<Icon
									name="arrowDownMultiSelect"
									style={{
										position: "absolute",
										width: "16px",
										height: "16px",
										right: "16px",
									}}
								/>
							)}
							sx={{
								"borderRadius": "10px",
								"height": "59px",
								"backgroundColor": "white.main",
								"width": "100%",
								"fontSize": "18px",
								"lineHeight": "27px",
								"fontFamily": "Inter",
								"color": "#232323",
								"& .MuiOutlinedInput-notchedOutline": {
									borderWidth: "1.5px",
									borderColor: errors?.length ? "#EF5457" : "#D9D9D9",
								},
								"&:hover .MuiOutlinedInput-notchedOutline": {
									borderColor: errors?.length ? "#EF5457" : "#D9D9D9",
								},
								"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
									borderColor: errors?.length ? "#EF5457" : "#61AEA0",
								},
							}}
						>
							{items.map((item) => (
								<MenuItem key={item.id} value={item.id}>
									{item.name}
								</MenuItem>
							))}
						</SelectMUI>
					</Box>
				)}
				{errors?.length
					? errors.map((error) => (
							<Box
								key={`${name}-select-${error}`}
								sx={{
									display: "flex",
									mt: "5px",
								}}
							>
								<Typography
									sx={{
										fontSize: "14px",
										lineHeight: "17px",
										fontWeight: "500",
										color: "#EF5457",
									}}
								>
									{error}
								</Typography>
							</Box>
						))
					: null}
			</Box>
		</Box>
	);
};
