import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { colors } from "../../styles/colors";

const HEIGHT = 292;

export const HomeItemWrapper = ({
	title,
	loading,
	children,
	containerStyle,
}) => {
	if (loading) {
		return (
			<Skeleton
				variant="rounded"
				animation="wave"
				height={HEIGHT}
				sx={{
					borderRadius: "20px",
				}}
			/>
		);
	}

	return (
		<Box
			sx={{
				border: "1px solid #EFF0F6",
				boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				p: "20px 20px 0 20px",
				backgroundColor: colors.white,
				borderRadius: "20px",
				height: HEIGHT,
				overflow: "auto",
				position: "relative",
				...containerStyle,
			}}
		>
			<Typography
				sx={{
					fontFamily: "Nunito",
					fontWeight: "700",
					fontSize: 18,
					lineHeight: "24px",
					px: "8px",
					mb: "12px",
					zIndex: 1,
				}}
			>
				{title}
			</Typography>
			<Box
				sx={{
					height: "1px",
					backgroundColor: "#E4E5E7",
					px: "8px",
					mb: "20px",
				}}
			/>
			{children}
		</Box>
	);
};
