import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Navigation } from "./navigation/Navigation";
import store from "./store";
import { ThemeProvider } from "@emotion/react";
import { defaultTheme } from "./styles/theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import packageJson from "../package.json";
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	release: packageJson.version,
	environment: process.env.REACT_APP_ENVIRONMENT,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<LocalizationProvider dateAdapter={AdapterDayjs}>
		<ThemeProvider theme={defaultTheme}>
			<Provider store={store}>
				<Navigation />
			</Provider>
		</ThemeProvider>
	</LocalizationProvider>,
);

reportWebVitals();
