export const apiAddresses = {
	SIGN_IN: "sign-in",
	SIGN_UP: "sign-up",
	OFFERS: "offers",
	OFFER: "offer",
	OFFER_ID: (offerId) => `offer/${offerId}`,
	OFFER_CHANGE_STATUS: (offerId) => `offer-change-status/${offerId}`,
	CUSTOMER_PERSONAS: "customerpersonas",
	PERFORMANCE: (offerId) => `performance/${offerId}`,
	PERFORMANCE_CSV: (offerId) => `performance-csv/${offerId}`,
	CATALOGS: "catalogs",
	COMPETITORS: "competitors",
	CUSTOMER_BEHAVIOR: "customerbehavior",
	HOME: "home",
	USER_INFO: "user-info",
	BRAND: "company-brand",
	UPDATE_BRAND: "company-brand-update",
	CERTIFICATE_UPLOAD: "certificate-upload",
	VERIFY_EMAIL: "verify-code",
	RESEND_VERIFICATION_EMAIL: "resend-code",
	CREATE_BRAND_DATA: "create-company-brand-data",
	SUBSCRIPTION_DETAILS: "subscription-details",
	CHECK_EMAIL: "check-email",
};
