import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { offerColors } from "../../styles/colors";

export const OfferStatus = ({ status, shownNotStarted = false }) => {
	const statusColors = useMemo(() => {
		switch (status) {
			case "Active":
				return offerColors.active;
			case "Expired":
				return offerColors.expired;
			case "Paused":
				return offerColors.paused;
			case "Not-started":
				return shownNotStarted ? offerColors.notStarted : "transparent";

			default:
				return null;
		}
	}, [status]);

	if (!statusColors || (!shownNotStarted && status === "Not-started")) {
		return null;
	}

	return (
		<Box
			sx={{
				px: "8px",
				height: "20px",
				backgroundColor: statusColors.statusBackground,
				borderRadius: 100,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Typography sx={{ fontSize: 10, color: statusColors.statusText }}>
				{status}
			</Typography>
		</Box>
	);
};
