import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { colors } from "../../styles/colors";
import { HomeItemWrapper } from "./HomeItemWrapper";
import { NoData } from "../NoData";

export const HomeCompetitorsTable = ({ competitors, loading }) => {
	const renderCompetitorsTable = useMemo(() => {
		if (!competitors?.length) {
			return <NoData />;
		}

		return (
			<>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						mb: "16px",
					}}
				>
					<Typography
						sx={{
							width: "15%",
							fontFamily: "Nunito",
							fontWeight: "400",
							fontSize: 12,
							lineHeight: "18px",
							textAlign: "center",
						}}
					>
						Rank
					</Typography>
					<Typography
						sx={{
							width: "55%",
							fontFamily: "Nunito",
							fontWeight: "400",
							fontSize: 12,
							lineHeight: "18px",
						}}
					>
						Brand Name
					</Typography>
				</Box>
				{competitors.map((competitor) => (
					<Box
						key={competitor.id}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							backgroundColor: competitor.userBrand
								? "primary.lightLight"
								: "white.main",
							alignItems: "center",
							height: "55px",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "400",
								fontSize: 12,
								lineHeight: "16px",
								color: competitor.userBrand ? "primary.main" : "black.main",
								width: "15%",
								textAlign: "center",
							}}
						>
							{competitor.rank}
						</Typography>
						<Box
							sx={{
								width: "30%",
								pl: "6%",
							}}
						>
							<Box
								sx={{
									border: "1px solid #EEF6F4",
									width: "37px",
									height: "37px",
									borderRadius: "50%",
									backgroundColor: colors.white,
								}}
							>
								<img
									alt={`${competitor.name} Logo`}
									data-testid="competitor-item-image"
									src={competitor.picture}
									style={{
										width: "100%",
										height: "100%",
										borderRadius: "50%",
									}}
								/>
							</Box>
						</Box>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "600",
								fontSize: 12,
								lineHeight: "16px",
								color: "primary.main",
								width: "55%",
								pr: "8px",
							}}
						>
							{competitor.name}
						</Typography>
					</Box>
				))}
			</>
		);
	}, [competitors]);

	return (
		<HomeItemWrapper title={"Competitors Ranking"} loading={loading}>
			{renderCompetitorsTable}
		</HomeItemWrapper>
	);
};
