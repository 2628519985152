import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "./Icon";

export const TableColumn = ({ name, onPress, containerStyle }) => (
	<Box
		sx={{
			"display": "flex",
			"alignItems": "center",
			"justifyContent": "center",
			"&:hover": {
				cursor: "pointer",
			},
			...containerStyle,
		}}
		onClick={onPress}
	>
		<Typography
			sx={{
				fontFamily: "Nunito",
				fontSize: 12,
				lineHeight: "16px",
				mr: "10px",
			}}
		>
			{name}
		</Typography>
		<Icon name={"arrowDownTableColumn"} />
	</Box>
);
