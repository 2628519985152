import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetCustomerBehavior } from "../api";
import { errorHandler } from "../utils/errorHandling";

const customerBehaviorInitialState = {
	customerBehavior: null,
};

export const fetchCustomerBehavior = createAsyncThunk(
	"customerBehavior/fetchCustomerBehavior",
	async ({ date_from, date_to, onFinish }) => {
		try {
			const response = await apiGetCustomerBehavior({ date_to, date_from });
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
		}
	},
);

const customerBehaviorSlice = createSlice({
	name: "customerBehavior",
	initialState: customerBehaviorInitialState,
	reducers: {
		resetCustomerBehavior: (state) => {
			state.customerBehavior = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCustomerBehavior.fulfilled, (state, { payload }) => {
			state.customerBehavior = payload;
		});
	},
});

export const customerBehaviorReducer = customerBehaviorSlice.reducer;
export const { resetCustomerBehavior } = customerBehaviorSlice.actions;
