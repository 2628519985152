import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetHome } from "../api";
import { errorHandler } from "../utils/errorHandling";

const homeInitialState = {
	home: {
		offers: null,
		competitors: null,
	},
	loading: false,
};

export const fetchHomeData = createAsyncThunk(
	"home/fetchHomeData",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiGetHome();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoading(false));
		}
	},
);

const homeSlice = createSlice({
	name: "home",
	initialState: homeInitialState,
	reducers: {
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		resetHome: (state) => {
			state.home = {
				offers: null,
				competitors: null,
			};
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchHomeData.fulfilled, (state, { payload }) => {
			state.home = payload;
		});
	},
});

export const homeReducer = homeSlice.reducer;
export const { setLoading, resetHome } = homeSlice.actions;
