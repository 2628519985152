import { Box, ButtonBase, Paper } from "@mui/material";
import React, { useState } from "react";
import { Checkbox } from "./Checkbox";
import { Icon } from "./Icon";

export const MultiSelectNested = ({
	data,
	selectedItems,
	setSelectedItems,
}) => {
	const [expandedNodes, setExpandedNodes] = useState([]);

	const handleExpand = (nodeId) => {
		setExpandedNodes((prevExpandedNodes) =>
			prevExpandedNodes.includes(nodeId)
				? prevExpandedNodes.filter((id) => id !== nodeId)
				: [...prevExpandedNodes, nodeId],
		);
	};

	const handleCheck = (_, node) => {
		const newSelectedItems = updateSelectedItems(selectedItems, node);
		setSelectedItems(newSelectedItems);
	};

	const isNodeSelected = (node) => {
		return selectedItems.some((item) => item.id === node.id);
	};

	const renderTree = (node, numberBlock, isLastItemFirstBlock) => (
		<Box key={node.id}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					height: "32px",
					mb: isLastItemFirstBlock ? "13px" : "5px",
					px: `${numberBlock === 1 ? 16 : numberBlock * 24}px`,
				}}
			>
				<Checkbox
					checked={isNodeSelected(node)}
					name={node.name}
					disableRipple
					textStyle={{
						fontWeight: `${
							numberBlock === 0 ? "700" : numberBlock === 1 ? "500" : "400"
						}`,
						fontSize: 16,
						lineHeight: "19px",
					}}
					onChange={(e) => handleCheck(e, node)}
					containerStyle={{
						width: "100%",
					}}
				/>
				{Array.isArray(node.children) && (
					<ButtonBase
						sx={{
							height: "100%",
							pl: "10px",
							pr: numberBlock === 0 ? 0 : "10px",
							right: numberBlock === 0 ? 0 : "-10px",
						}}
						onClick={() => handleExpand(node.id)}
						disableRipple
					>
						{expandedNodes.includes(node.id) ? (
							<Icon name="minusSelect" />
						) : (
							<Icon name="plusSelect" />
						)}
					</ButtonBase>
				)}
			</Box>
			{Array.isArray(node.children) &&
				expandedNodes.includes(node.id) &&
				node.children.map((child) => renderTree(child, numberBlock + 1))}
		</Box>
	);

	const renderData = (data) => {
		return data.map((item, index) =>
			renderTree(item, 0, data.length - 1 === index),
		);
	};

	const updateSelectedItems = (currentSelectedItems, node) => {
		const newSelectedItems = [...currentSelectedItems];

		// Toggle the selected state of the current node
		const nodeIndex = newSelectedItems.findIndex((item) => item.id === node.id);
		if (nodeIndex !== -1) {
			newSelectedItems.splice(nodeIndex, 1);
		} else {
			newSelectedItems.push({ id: node.id, name: node.name });
		}

		// Update parent nodes
		const parentNode = findParentNode(data, node);
		if (parentNode) {
			const parentNodeIndex = newSelectedItems.findIndex(
				(item) => item.id === parentNode.id,
			);
			const allChildrenSelected = parentNode.children.every((child) =>
				newSelectedItems.some((item) => item.id === child.id),
			);

			if (allChildrenSelected && parentNodeIndex === -1) {
				newSelectedItems.push({ id: parentNode.id, name: parentNode.name });
			} else if (!allChildrenSelected && parentNodeIndex !== -1) {
				newSelectedItems.splice(parentNodeIndex, 1);
			}
		}

		return newSelectedItems;
	};

	const findParentNode = (tree, nodeToFind) => {
		for (const node of tree) {
			if (node.children) {
				if (node.children.some((child) => child.id === nodeToFind.id)) {
					return node;
				}
				const parentNode = findParentNode(node.children, nodeToFind);
				if (parentNode) {
					return parentNode;
				}
			}
		}
		return null;
	};

	return (
		<Paper
			sx={{
				boxShadow: 0,
				overflow: "auto",
				maxHeight: "600px",
			}}
		>
			{renderData(data)}
		</Paper>
	);
};
