import React from "react";
import { HomeItemWrapper } from "./HomeItemWrapper";
import { Box } from "@mui/material";
import { PieChart } from "../PieChart";
import { chartColors } from "../../styles/colors";
import { NoData } from "../NoData";

export const HomeTypeClient = ({ data, loading }) => (
	<HomeItemWrapper title={"Type of Client"} loading={loading}>
		{data ? (
			<Box display="flex" justifyContent="space-around">
				<PieChart
					percentage={data.newClients}
					value={data.newClients}
					colors={[chartColors.green, chartColors.lightGreen]}
					name="New Clients"
					units={"%"}
					containerStyle={{
						minWidth: "160px",
						maxWidth: "160px",
						px: "7px",
					}}
				/>
				<Box sx={{ width: "24px" }} />
				<PieChart
					percentage={data.returningClients}
					value={data.returningClients}
					colors={[chartColors.blue, chartColors.lightPurple]}
					name="Returning Clients"
					units={"%"}
					containerStyle={{
						minWidth: "160px",
						maxWidth: "160px",
						px: "7px",
					}}
				/>
			</Box>
		) : (
			<NoData />
		)}
	</HomeItemWrapper>
);
