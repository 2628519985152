import React, { useMemo } from "react";
import { Icon } from "./Icon";
import { TimePicker as TimePickerMUI } from "@mui/x-date-pickers";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { colors } from "../styles/colors";

export const TimePicker = ({ value, onChange, name, label, disabled }) => {
	const stylesDisable = useMemo(
		() => ({
			backgroundColor: disabled ? "#f6f6f7" : colors.white,
			color: disabled ? "#4c4b67" : "#030229",
			opacity: disabled ? 0.5 : 1,
		}),
		[disabled],
	);

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				sx={{
					fontSize: 16,
					lineHeight: "19px",
					color: stylesDisable.color,
					mb: "8px",
				}}
			>
				{label}
			</Typography>
			<Box
				sx={{
					position: "relative",
					height: "50px",
					border: "1px solid #8FA0B4",
					borderRadius: "10px",
					backgroundColor: stylesDisable.backgroundColor,
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					px: "20px",
				}}
			>
				<Typography sx={{ fontSize: 12, color: stylesDisable.color }}>
					{dayjs(value).format("hh:mm a")}
				</Typography>
				<Icon name="clock" style={{ opacity: stylesDisable.opacity }} />
				<TimePickerMUI
					value={value}
					onChange={onChange}
					name={name}
					label={label}
					disabled={disabled}
					sx={{
						position: "absolute",
						left: 0,
						top: 0,
						opacity: 0,
						zIndex: 1,
					}}
					slotProps={{
						openPickerButton: {
							style: {
								width: "100%",
								height: "50px",
								position: "absolute",
								left: 0,
								top: 0,
								borderRadius: "10px",
							},
						},
					}}
				/>
			</Box>
		</Box>
	);
};
