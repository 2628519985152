import React, { useMemo } from "react";
import { Box, Input, Typography } from "@mui/material";
import { colors } from "../styles/colors";

export const TextInput = ({
	value,
	onChange,
	label,
	name,
	error,
	helperText,
	units,
	disabled = false,
	placeholder,
	type,
	containerStyle,
}) => {
	const stylesDisable = useMemo(
		() => ({
			backgroundColor: disabled ? "#f6f6f7" : colors.white,
			color: disabled ? "#4c4b67" : "#030229",
		}),
		[disabled],
	);

	return (
		<Box sx={{ mb: "8px", ...containerStyle }}>
			{label ? (
				<Typography
					sx={{
						fontSize: 16,
						lineHeight: "19px",
						mb: "8px",
						color: stylesDisable.color,
					}}
				>
					{label}
				</Typography>
			) : null}
			<Box
				sx={{
					position: "relative",
				}}
			>
				<Input
					value={value}
					onChange={onChange}
					name={name}
					type={type}
					placeholder={placeholder}
					disabled={disabled}
					disableUnderline={true}
					style={{
						border: "1px solid #8FA0B4",
						borderRadius: "10px",
						width: "100%",
						height: "50px",
						paddingLeft: "20px",
						paddingRight: units ? "50px" : "20px",
						fontSize: 12,
						backgroundColor: stylesDisable.backgroundColor,
					}}
					inputProps={{
						style: {
							color: disabled ? "#4c4b67" : "#030229",
						},
					}}
				/>
				<Box
					display="flex"
					alignItems="center"
					sx={{
						position: "absolute",
						right: "20px",
						top: 0,
						height: "100%",
					}}
				>
					<Typography sx={{ fontSize: 12, color: stylesDisable.color }}>
						{units}
					</Typography>
				</Box>
			</Box>
			{error && !disabled ? (
				<Typography
					sx={{
						fontSize: 12,
						lineHeight: "14px",
						mt: "2px",
						color: "error.main",
						ml: "10px",
					}}
				>
					{helperText}
				</Typography>
			) : (
				<Box sx={{ height: "16px" }} />
			)}
		</Box>
	);
};
