import React from "react";
import { ResponsiveBar } from "@nivo/bar";

export const BarChart = ({
	data,
	layout,
	key,
	indexBy,
	axisBottom,
	axisLeft,
	maxValue,
	colors,
	units,
	margin,
}) => (
	<ResponsiveBar
		role="bar-chart"
		data={data}
		key={key}
		indexBy={indexBy}
		margin={{ ...margin, bottom: 70 }}
		padding={0.2}
		maxValue={maxValue}
		colors={colors}
		colorBy="indexValue"
		layout={layout}
		borderRadius={10}
		axisTop={null}
		axisRight={null}
		axisBottom={axisBottom}
		axisLeft={axisLeft}
		label={(item) => `${item.value} ${units ?? ""}`}
		enableGridY={false}
		labelSkipWidth={21}
		labelSkipHeight={16}
		labelTextColor={{
			from: "color",
			modifiers: [["darker", "1.7"]],
		}}
		isInteractive={false}
	/>
);
