import { createSlice } from "@reduxjs/toolkit";

const uiInitialState = {
	snackBar: {
		open: false,
		text: "",
		severity: "success",
		duration: 3000,
	},
};

export const uiSlice = createSlice({
	name: "uiSlice",
	initialState: uiInitialState,
	reducers: {
		hideSnackBar: (state) => {
			state.snackBar = { ...state.snackBar, open: false };
		},
		showSnackBar: (state, { payload }) => {
			state.snackBar = {
				...payload,
				open: true,
			};
		},
		resetUi: (state) => {
			state.snackBar = uiInitialState.snackBar;
		},
	},
});

export const uiReducer = uiSlice.reducer;
export const { showSnackBar, hideSnackBar, resetUi } = uiSlice.actions;
