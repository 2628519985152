import React from "react";
import { Box, Typography } from "@mui/material";

export const NoData = ({ style = {} }) => (
	<Box
		display="flex"
		justifyContent="center"
		alignItems="center"
		sx={{ ...style, height: 200 }}
		data-testid="no-data"
	>
		<Typography variant="h6" sx={{ color: "black.light" }}>
			No Data
		</Typography>
	</Box>
);
