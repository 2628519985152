import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

const OtpTextField = styled(TextField)({
	"& .MuiInputBase-input": {
		fontSize: 20,
		fontWeight: 500,
		lineHeight: "24px",
		textAlign: "center",
	},
	"& .MuiOutlinedInput-root": {
		"height": "60px",
		"padding": "8px, 8px, 8px, 12px",
		"border": "1px",
		"borderRadius": "8px",
		"&:hover fieldset": {
			borderColor: "#D9D9D9",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#61AEA0",
		},
	},
	"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
		"-webkit-appearance": "none",
		"margin": 0,
	},
	"& input[type=number]": {
		"-moz-appearance": "textfield",
	},
	"&:not(:last-child)": {
		marginRight: "20px",
	},
});

export default function OtpTextInput(props) {
	const [otpValues, setOtpValues] = useState(Array(props.number).fill(""));
	const otpRefs = useRef([]);

	const handleKeyDown = (e, index) => {
		if (e.key === "Backspace" && otpValues[index] === "") {
			e.preventDefault();
			if (index > 0) {
				otpRefs.current[index - 1].focus();
			}
		}
	};

	const handleOtpChange = (e, index) => {
		const value = e.target.value;
		if (!/^[0-9]?$/.test(value)) {
			return;
		}
		const newValues = [...otpValues];
		newValues[index] = value;
		setOtpValues(newValues);
		props.setVerificationCode(newValues.join(""));

		if (value) {
			if (index < props.number - 1) {
				otpRefs.current[index + 1].focus();
			}
		}
	};

	useEffect(() => {
		otpRefs.current = otpRefs.current.slice(0, props.number);
	}, [props.number]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "flex-start",
				width: "fit-content",
				mb: "32px",
				...props.containerStyle,
			}}
		>
			{Array(props.number)
				.fill(null)
				.map((_, index) => (
					<OtpTextField
						{...props}
						key={index}
						type="number"
						inputProps={{ maxLength: 1 }}
						value={otpValues[index]}
						onChange={(e) => handleOtpChange(e, index)}
						onKeyDown={(e) => handleKeyDown(e, index)}
						inputRef={(ref) => (otpRefs.current[index] = ref)}
					/>
				))}
		</Box>
	);
}
