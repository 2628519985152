import React from "react";
import { Box, Typography } from "@mui/material";
import { alpha } from "@mui/system";
import { APPROVAL_IDS } from "../constants";

export const StatusTag = ({ statusId }) => {
	const status = APPROVAL_IDS[statusId];
	let fontColor;

	switch (status) {
		case "Approved":
		case "Verified":
		case "Active":
			fontColor = "#61AEA0";
			break;
		case "Unverified":
			fontColor = "#FA94A6";
			break;
		case "In Review":
			fontColor = "#FFB293";
			break;
		default:
			fontColor = "";
	}

	if (!fontColor) {
		return null;
	}

	return (
		<Box
			sx={{
				px: "8px",
				height: "20px",
				width: "fit-content",
				backgroundColor: alpha(fontColor, 0.15),
				borderRadius: 100,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Typography
				sx={{
					fontFamily: "Roboto",
					fontSize: 10,
					fontWeight: 400,
					lineHeight: "12px",
					textAlign: "left",
					color: fontColor,
				}}
			>
				{status}
			</Typography>
		</Box>
	);
};
