import React from "react";
import Typography from "@mui/material/Typography";

export const AuthSwiperSlide = ({ children }) => {
	return (
		<Typography
			sx={{
				userSelect: "none",
				fontFamily: "Inter",
				fontWeight: "700",
				fontSize: "64px",
				lineHeight: "96px",
				color: "white.main",
				overflow: "visible",
				mx: "80px",
			}}
		>
			{children.split("\n").map((line, index) => (
				<React.Fragment key={index}>
					{line}
					<br />
				</React.Fragment>
			))}
		</Typography>
	);
};
