import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Screen } from "../components/Screen";
import { BrandSettings } from "../components/BrandSettings";
import { Outlet } from "react-router-dom";
import { BillingSettings } from "components/BillingSettings";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ pt: "24px" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const a11yProps = (index) => {
	return {
		"id": `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
		"sx": { width: "100px", p: 0, mr: "40px" },
	};
};

export const Settings = () => {
	const [selectedTab, setSelectedTab] = useState(1);

	const handleTabChange = (_, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<Screen title="Settings" hideSeparator>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={selectedTab}
					onChange={handleTabChange}
					aria-label="settings tabs"
					sx={{
						"position": "relative",
						".MuiTab-root": {
							fontWeight: "500",
							fontSize: "16px",
							lineHeight: "19px",
							color: "#2C2C2C",
							textTransform: "none",
						},
						"&:before": {
							content: '""',
							position: "absolute",
							left: 0,
							right: 0,
							bottom: 0,
							height: "1px",
							backgroundColor: "#e0e0e0",
						},
					}}
				>
					<Tab label="User details" {...a11yProps(0)} />
					<Tab label="Brand" {...a11yProps(1)} />
					<Tab label="Billing" {...a11yProps(2)} />
				</Tabs>
			</Box>
			<TabPanel value={selectedTab} index={0}>
				It&apos;s developing now, will be released soon.
			</TabPanel>
			<TabPanel value={selectedTab} index={1}>
				<BrandSettings />
			</TabPanel>
			<TabPanel value={selectedTab} index={2}>
				<BillingSettings />
			</TabPanel>
			<Outlet />
		</Screen>
	);
};
