import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompetitors, setCompetitors } from "../store/competitorsSlice";
import { FilterCompetition } from "../components/FilterCompetition";
import { CompetitorItem } from "../components/CompetitorItem";
import { NoData } from "../components/NoData";
import { Screen } from "../components/Screen";
import { SkeletonCompetitorsTable } from "../components/skeletons/SkeletonCompetitorsTable";
import { SkeletonCompetitorsLogo } from "../components/skeletons/SkeletonCompetitorsLogo";
import { TableColumn } from "../components/TableColumn";
import { sortByField } from "../utils/sort";

export const Competition = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const competitors = useSelector(({ competitors }) => competitors.competitors);
	const [topCompetitors, setTopCompetitors] = useState([]);
	const selectedBrand = useSelector(({ brands }) => brands.selectedBrand);

	const handleFetchCompetition = useCallback(
		(params) => {
			setLoading(true);

			dispatch(fetchCompetitors({ params, onFinish: () => setLoading(false) }));
		},
		[dispatch],
	);

	useEffect(() => {
		if (!selectedBrand) {
			return;
		}

		handleFetchCompetition({});
	}, [handleFetchCompetition, selectedBrand]);

	useEffect(() => {
		if (!topCompetitors.length) {
			const filteredCompetitors = competitors.filter(
				(competitor) => !competitor.userBrand,
			);

			setTopCompetitors(filteredCompetitors.slice(0, 5));
		}
	}, [topCompetitors, competitors]);

	const handleSort = (field) => {
		const sortedCompetitors = [...competitors].sort(sortByField(field));

		dispatch(setCompetitors(sortedCompetitors));
	};

	return (
		<Screen title={"Competition"}>
			<Typography
				sx={{
					fontWeight: 500,
					fontSize: 20,
					lineHeight: "24px",
					marginBottom: "24px",
				}}
			>
				Top competitors
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					mb: "24px",
				}}
				flexWrap="wrap"
			>
				{loading ? (
					<SkeletonCompetitorsLogo />
				) : (
					topCompetitors.slice(0, 5).map(({ picture, name }, i) => (
						<img
							key={picture}
							alt={`${name} Logo`}
							src={picture}
							data-testid={`logo-top-competitor-${i}`}
							style={{
								borderRadius: "50%",
								height: "204px",
								width: "204px",
								border: "1px solid #EFF0F6",
							}}
						/>
					))
				)}
			</Box>
			<Box
				sx={{
					borderRadius: "20px",
					p: "40px",
					backgroundColor: "white.main",
					border: "1px solid #EFF0F6",
				}}
			>
				<Box display="flex" justifyContent="space-between">
					<Typography
						sx={{
							fontFamily: "Nunito",
							fontWeight: 700,
							fontSize: 18,
							lineHeight: "24px",
							mb: "24px",
						}}
					>
						Competitors Ranking
					</Typography>
					{!loading && (
						<FilterCompetition handleDone={handleFetchCompetition} />
					)}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: "16px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "28%",
						}}
					>
						<TableColumn
							name={"Rank"}
							onPress={() => {}}
							containerStyle={{ width: "48px" }}
						/>
						<TableColumn
							name={"Brand Name"}
							onPress={() => handleSort("name")}
							containerStyle={{
								width: "150px",
								justifyContent: "flex-start",
							}}
						/>
					</Box>
					<TableColumn
						name={"No.of Transactions"}
						onPress={() => handleSort("noTransactions")}
						containerStyle={{
							width: "18%",
						}}
					/>
					<TableColumn
						name={"AOV"}
						onPress={() => handleSort("aov")}
						containerStyle={{
							width: "18%",
						}}
					/>
					<TableColumn
						name={"Sales"}
						onPress={() => handleSort("sales")}
						containerStyle={{
							width: "18%",
						}}
					/>
					<TableColumn
						name={"No. of customers"}
						onPress={() => handleSort("noCustomers")}
						containerStyle={{
							width: "18%",
							justifyContent: "flex-end",
						}}
					/>
				</Box>
				{loading ? (
					<SkeletonCompetitorsTable />
				) : competitors.length ? (
					competitors.map((competitor, index) => (
						<CompetitorItem
							key={competitor.id}
							data={competitor}
							index={index}
						/>
					))
				) : (
					<NoData />
				)}
			</Box>
		</Screen>
	);
};
