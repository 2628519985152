import React from "react";
import { Icon } from "./Icon";
import { DatePicker as DatePickerMUI } from "@mui/x-date-pickers";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

export const DatePicker = ({ value, onChange, name, label }) => {
	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				sx={{ fontSize: 16, lineHeight: "19px", color: "#030229", mb: "8px" }}
			>
				{label}
			</Typography>
			<Box
				sx={{
					position: "relative",
					height: "50px",
					border: "1px solid #8FA0B4",
					borderRadius: "10px",
					backgroundColor: "gray",
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					px: "20px",
				}}
			>
				<Typography sx={{ fontSize: 12, color: "#030229" }}>
					{dayjs(value).format("DD/MM/YYYY")}
				</Typography>
				<Icon name="calendar" />
				<DatePickerMUI
					value={value}
					onChange={onChange}
					name={name}
					sx={{
						position: "absolute",
						left: 0,
						top: 0,
						opacity: 0,
						zIndex: 1,
					}}
					label={label}
					slotProps={{
						openPickerButton: {
							style: {
								width: "100%",
								height: "50px",
								position: "absolute",
								left: 0,
								top: 0,
								borderRadius: "10px",
							},
						},
					}}
				/>
			</Box>
		</Box>
	);
};
