import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";

const LineChart = ({ data, color }) => (
	<ResponsiveLine
		data={data}
		colors={[color]}
		margin={{ top: 71, bottom: 10, left: -1, right: -1 }}
		enablePoints={false}
		curve="monotoneX"
		enableArea
		axisLeft={null}
		axisBottom={null}
		enableGridX={false}
		enableGridY={false}
		lineWidth={4}
		defs={[
			linearGradientDef("gradientA", [
				{ offset: 0, color: "inherit", opacity: 1 },
				{ offset: 60, color: "inherit", opacity: 1 },
				{ offset: 100, color: "inherit", opacity: 0 },
			]),
		]}
		fill={[{ match: "*", id: "gradientA" }]}
	/>
);

export const CustomerBehaviorLineChart = ({
	icon,
	iconBackgroundColor,
	data,
	color,
	name,
	units,
	loading,
}) => {
	if (loading) {
		return (
			<Skeleton
				variant="rounded"
				animation="wave"
				height={181}
				sx={{
					borderRadius: "10px",
				}}
			/>
		);
	}

	if (!data) {
		return null;
	}

	return (
		<Box
			sx={{
				borderRadius: "10px",
				backgroundColor: "white.main",
				height: "181px",
				position: "relative",
				pt: "20px",
			}}
			data-testid="customer-behavior-line-chart"
		>
			<Box
				sx={{
					position: "absolute",
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					px: "20px",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "flex-end" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "64px",
							height: "64px",
							borderRadius: "50%",
							backgroundColor: iconBackgroundColor,
							mr: "15px",
						}}
						data-testid="customer-behavior-line-chart-icon"
					>
						{icon}
					</Box>
					<Box>
						<Typography
							data-testid="customer-behavior-line-chart-name"
							sx={{
								fontFamily: "Nunito",
								fontSize: 14,
								lineHeight: "19px",
								color: "#030229",
								mb: "7px",
							}}
						>
							{name}
						</Typography>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "700",
								fontSize: 24,
								lineHeight: "33px",
								color: "#030229",
							}}
							data-testid="customer-behavior-line-chart-amount"
						>
							{data.amount.toLocaleString()}
							{units ? (
								<Box sx={{ fontSize: 16, fontWeight: "600" }} component="span">
									{` ${units}`}
								</Box>
							) : null}
						</Typography>
					</Box>
				</Box>
			</Box>
			<LineChart data={data.chartData} color={color} />
		</Box>
	);
};
