import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import backgroundSwiper from "../../assets/images/background-sign-in-swiper.png";
import mthmrLogo from "../../assets/images/mthmr-logo-white.png";
import { AUTH_SWIPER_SLIDES } from "../../constants";
import { AuthSwiperSlide } from "./AuthSwiperSlide";
import { AuthSwiperPagination } from "./AuthSwiperPagination";

export const AuthSwiper = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [isDragging, setIsDragging] = useState(false);
	const [startPos, setStartPos] = useState(0);
	const [containerWidth, setContainerWidth] = useState(0);
	const sliderRef = useRef();
	const slideRefs = useRef([]);

	useEffect(() => {
		// Delay measurements until next tick to ensure all styles and images are loaded
		const timeoutId = setTimeout(() => {
			const maxWidth = slideRefs.current.reduce((max, slide) => {
				return slide ? Math.max(max, slide.offsetWidth) : max;
			}, 0);
			setContainerWidth(maxWidth);
		}, 50);

		return () => clearTimeout(timeoutId);
	}, []);

	const handleStart = (position) => {
		setIsDragging(true);
		setStartPos(position);
	};

	const handleMove = (position) => {
		if (!isDragging) return;
		const diff = startPos - position;
		if (diff > 50 && activeStep < AUTH_SWIPER_SLIDES.length - 1) {
			setActiveStep(activeStep + 1);
			setIsDragging(false);
		} else if (diff < -50 && activeStep > 0) {
			setActiveStep(activeStep - 1);
			setIsDragging(false);
		}
	};

	const handleEnd = () => {
		setIsDragging(false);
	};

	useEffect(() => {
		const node = sliderRef.current;

		const handleMouseDown = (e) => {
			handleStart(e.clientX);
			e.preventDefault(); // Prevent text selection during swipe
		};

		const handleMouseMove = (e) => {
			if (isDragging) {
				handleMove(e.clientX);
			}
		};

		const handleMouseUp = () => {
			handleEnd();
		};

		const handleTouchStart = (e) => {
			handleStart(e.touches[0].clientX);
		};

		const handleTouchMove = (e) => {
			if (isDragging) {
				handleMove(e.touches[0].clientX);
			}
		};

		const handleTouchEnd = () => {
			handleEnd();
		};

		node.addEventListener("mousedown", handleMouseDown);
		node.addEventListener("mousemove", handleMouseMove);
		node.addEventListener("mouseup", handleMouseUp);
		node.addEventListener("mouseleave", handleEnd); // Handle mouse exit while dragging

		node.addEventListener("touchstart", handleTouchStart);
		node.addEventListener("touchmove", handleTouchMove);
		node.addEventListener("touchend", handleTouchEnd);

		return () => {
			node.removeEventListener("mousedown", handleMouseDown);
			node.removeEventListener("mousemove", handleMouseMove);
			node.removeEventListener("mouseup", handleMouseUp);
			node.removeEventListener("mouseleave", handleEnd);

			node.removeEventListener("touchstart", handleTouchStart);
			node.removeEventListener("touchmove", handleTouchMove);
			node.removeEventListener("touchend", handleTouchEnd);
		};
	}, [isDragging, startPos, activeStep]);

	return (
		<Box
			sx={{
				height: "100%",
				position: "relative",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				overflow: "hidden",
			}}
		>
			<img
				src={backgroundSwiper}
				style={{
					height: "100%",
					width: "100%",
					borderRadius: "24px",
					position: "absolute",
					pointerEvents: "none",
				}}
			/>
			<Box
				ref={sliderRef}
				sx={{
					width: containerWidth,
					position: "relative",
					overflow: "hidden",
				}}
			>
				<img
					src={mthmrLogo}
					style={{
						width: "115px",
						height: "35px",
						pointerEvents: "none",
						zIndex: 1,
						marginBottom: "20px",
						marginLeft: "80px",
					}}
				/>
				<Box
					sx={{
						display: "flex",
						transition: "transform 0.5s",
						transform: `translateX(-${activeStep * 100}%)`,
						mb: "80px",
					}}
				>
					{AUTH_SWIPER_SLIDES.map((text, index) => (
						<Box
							key={index}
							sx={{ flexShrink: 0, width: "max-content" }} // Using max-content to ensure content dictates the size
							ref={(el) => (slideRefs.current[index] = el)} // Assign refs to each slide
						>
							<AuthSwiperSlide>{text}</AuthSwiperSlide>
						</Box>
					))}
				</Box>
				<AuthSwiperPagination
					steps={AUTH_SWIPER_SLIDES.length}
					activeStep={activeStep}
				/>
			</Box>
		</Box>
	);
};
