import {
	setSelectedBrand,
	setFirstBrand,
	fetchUserInfo,
	createBrand,
} from "../brandsSlice";
import { axiosInstance } from "api/axios";

export const syncBrandIdMiddleware = (store) => (next) => (action) => {
	const result = next(action);

	// List of actions that update selectedBrand
	const brandActions = [
		setSelectedBrand,
		setFirstBrand,
		fetchUserInfo.fulfilled,
		createBrand.fulfilled,
	];

	if (brandActions.some((actionCreator) => actionCreator.match(action))) {
		const state = store.getState();
		const brandId = state.brands.selectedBrand?.id;

		if (brandId) {
			axiosInstance.defaults.params = {
				...axiosInstance.defaults.params,
				brandId,
			};
		} else {
			console.error("brandId is not available in the Axios params.");
		}
	}

	return result;
};
