import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Registration } from "../components/Registration";
import { SignIn } from "../components/SignIn";
import { AuthSwiper } from "../components/AuthSwiper/AuthSwiper";

export const Auth = () => {
	const [signUpShown, setSignUpShown] = useState(false);

	const switchToSignIn = () => setSignUpShown(false);

	const switchToSignUp = () => setSignUpShown(true);

	return (
		<Box
			sx={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				backgroundColor: "white.main",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					width: "41%",
					height: "100vh",
					p: "32px",
					overflowY: "auto",
				}}
			>
				{signUpShown ? (
					<Registration switchToSignIn={switchToSignIn} />
				) : (
					<SignIn switchToSignUp={switchToSignUp} />
				)}
			</Box>
			<Box
				sx={{
					p: "12px",
					width: "59%",
					height: "100vh",
					position: "sticky",
					top: 0,
				}}
			>
				<AuthSwiper />
			</Box>
		</Box>
	);
};
