import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, ButtonBase, List, Typography } from "@mui/material";
import { Icon } from "./Icon";

const ReorderCompetitors = ({
	setCompetitors,
	competitors,
	onReorder,
	containerStyle,
}) => {
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		onReorder(result.source.index, result.destination.index);
	};

	const onRemoveCompetitor = (competitorId) => {
		const filteredCompetitors = competitors.filter(
			(competitor) => competitor.id !== competitorId,
		);

		setCompetitors(filteredCompetitors);
	};

	if (!competitors.length) {
		return null;
	}

	return (
		<Box
			sx={{
				p: "14px 24px",
				backgroundColor: "#EEF6F4",
				borderRadius: "20px",
				...containerStyle,
			}}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="competitors">
					{(provided) => (
						<List {...provided.droppableProps} ref={provided.innerRef}>
							{competitors.map((competitor, index) => (
								<Draggable
									key={competitor.id.toString()}
									draggableId={`draggable-competitor-${competitor.id}`}
									index={index}
								>
									{(provided) => (
										<Box
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											sx={{
												height: "62px",
												display: "flex",
												alignItems: "center",
											}}
										>
											<Box
												sx={{
													position: "relative",
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													height: "50px",
													width: "100%",
													backgroundColor: "#FFFFFF",
													border: "1px solid #E4E4E4",
													borderRadius: "10px",
													padding: "0 20px",
												}}
											>
												<Box
													sx={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}
												>
													<Icon
														name="dragItem"
														style={{ marginRight: "22px" }}
													/>
													<Typography
														sx={{
															fontSize: 16,
															lineHeight: "20px",
															color: "#2C2C2C",
														}}
													>
														{competitor.name}
													</Typography>
												</Box>
												<ButtonBase
													disableRipple
													sx={{
														position: "absolute",
														right: 0,
														height: "100%",
														width: "50px",
													}}
													onClick={() => onRemoveCompetitor(competitor.id)}
												>
													<Icon name="closeBlack" />
												</ButtonBase>
											</Box>
										</Box>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</List>
					)}
				</Droppable>
			</DragDropContext>
		</Box>
	);
};

export default ReorderCompetitors;
