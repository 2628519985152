import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { AddOfferButton } from "./AddOfferButton";
import { SkeletonOffer } from "./skeletons/SkeletonOffer";
import { SkeletonOffers } from "./skeletons/SkeletonOffers";
import { Offer } from "./Offer/Offer";

export const OffersList = ({
	offers,
	loading,
	handlePressAddOffer = null,
	catalogs = null,
	skeletonsCount = 14,
	disableOfferDetails = true,
	containerStyle,
}) => {
	const offersLength = useMemo(() => offers?.length, [offers]);

	const renderAddButton = useMemo(() => {
		if (!handlePressAddOffer) {
			return;
		}

		return (
			<Grid item xs={4}>
				{catalogs ? (
					<AddOfferButton
						onClick={handlePressAddOffer}
						offersLength={offersLength}
					/>
				) : (
					<SkeletonOffer />
				)}
			</Grid>
		);
	}, [catalogs, handlePressAddOffer, offersLength]);

	return (
		<Grid container spacing={"24px"} sx={containerStyle}>
			{renderAddButton}
			{loading ? (
				<SkeletonOffers count={skeletonsCount} />
			) : (
				offers?.map((offer) => (
					<Grid data-testid="offer" item xs={4} key={`offer-${offer.id}`}>
						<Offer offer={offer} disableOfferDetails={disableOfferDetails} />
					</Grid>
				))
			)}
		</Grid>
	);
};
