import axios from "axios";
import { errorHandler } from "../utils/errorHandling";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
	paramsSerializer: {
		indexes: null,
	},
});

export const callApiWithToken = async (token, url, options) => {
	return axiosInstance({
		...options,
		url,
		headers: {
			authorization: `Bearer ${token}`,
			...options.headers,
		},
	});
};

export const authorizedRequest = async (url, options) => {
	const accessToken = localStorage.getItem("accessToken");

	if (accessToken) {
		try {
			return await callApiWithToken(accessToken, url, options);
		} catch (e) {
			if (e.response?.status === 401) {
				// Lazy load to avoid circular dependency
				const storeModule = await import("../store");
				const store = storeModule.default;
				const { resetStore } = await import("../store/rootReducer");

				// Dispatch resetStore to reset the Redux store
				store.dispatch(resetStore());
			} else {
				errorHandler(e, true);
			}
			return Promise.reject(e);
		}
	}
	throw new Error("Unauthorized");
};
