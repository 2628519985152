import React from "react";
import { Box, ButtonBase } from "@mui/material";
import { Icon } from "./Icon";
import ImageUploading from "react-images-uploading";
import { colors } from "styles/colors";

export const LogoUploader = ({
	logo,
	setLogo,
	containerStyle,
	isError = "",
	edit = false,
}) => {
	const onChangeImage = (imageList) => {
		if (!imageList.length) {
			return;
		}

		setLogo(imageList[0]);
	};

	const getImageSrc = () => {
		if (logo && typeof logo === "object") {
			return logo["image_url_key"];
		}
		return logo;
	};

	return (
		<Box sx={{ ...containerStyle, position: "relative" }}>
			<ImageUploading
				multiple
				onChange={onChangeImage}
				maxNumber={1}
				dataURLKey="image_url_key"
				acceptType={["png", "jpg", "jpeg"]}
				value={[]}
			>
				{({ onImageUpload, dragProps }) => (
					<Box
						sx={{
							height: "100%",
							width: "100%",
							borderRadius: "50%",
							borderWidth: "1px",
							borderStyle: "solid",
							borderColor: isError ? "#EF5457" : "#EFF0F6",
							backgroundColor: logo ? colors.white : "#F4F4F4",
							boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
							position: "relative",
						}}
					>
						<ButtonBase
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: "50%",
								position: "relative",
								overflow: "hidden",
							}}
							onClick={onImageUpload}
							{...dragProps}
						>
							{logo ? (
								<>
									<img
										src={getImageSrc()}
										alt="Brand Logo"
										style={{
											height: "100%",
											width: "100%",
											borderRadius: "50%",
											position: "absolute",
											top: 0,
											left: 0,
											objectFit: "cover",
											zIndex: 1,
										}}
									/>
									{edit && (
										<Box
											sx={{
												position: "absolute",
												top: 0,
												left: 0,
												right: 0,
												bottom: 0,
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												zIndex: 2,
												backgroundColor: "rgba(255, 255, 255, 0.5)",
												borderRadius: "50%",
											}}
										>
											<Icon
												name="camera"
												style={{
													opacity: 0.5,
													width: "38px",
													height: "38px",
												}}
											/>
										</Box>
									)}
								</>
							) : (
								<Icon
									name="camera"
									style={{
										zIndex: 1,
									}}
								/>
							)}
						</ButtonBase>
					</Box>
				)}
			</ImageUploading>
		</Box>
	);
};
