import { createTheme } from "@mui/material";
import { colors } from "./colors";

export const defaultTheme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
			light: colors.lightPrimary,
			lightLight: colors.lightLightPrimary,
			veryLight: colors.veryLightPrimary,
			dark: colors.darkPrimary,
		},
		black: {
			main: colors.black,
			light: colors.lightBlack,
			dark: colors.darkBlack,
		},
		white: {
			main: colors.white,
		},
		gray: {
			main: colors.gray,
			dark: colors.darkGray,
			darkDark: colors.darkDarkGray,
			light: colors.lightGray,
			lightLight: colors.lightLightGray,
		},
		error: {
			main: colors.red,
		},
	},
	components: {
		MuiSkeleton: {
			styleOverrides: {
				root: {
					backgroundColor: colors.gray,
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: "outlined", color: "info" },
					style: {
						backgroundColor: colors.white,
						border: "1px solid #D9D9D9",
						color: colors.primary,
					},
				},
			],
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						backgroundColor: "#F4F4F4",
					},
				},
			},
		},
	},
});
